import { message } from "antd";
import requests from "../../services/requests";
import { showSettings } from "../settings/action";
import types from "./types";

export const updateSettings = (newData, token, resetState) => {
    return async (dispatch) => {
        try {
            dispatch(UPDATE_SETTINGS_REQUEST());
            await requests.put(token, "/settings/update", {
                newData: newData
            });
            dispatch(UPDATE_SETTINGS_SUCCESS());
            dispatch(showSettings(
                token
            ));
            resetState();
            message.success("Successfully updated settings", 1);
        } catch (e) {
            dispatch(UPDATE_SETTINGS_FAILURE(e.response.data.message));
            message.error(e.response.data.message, 1);
        }
    };
};

export const UPDATE_SETTINGS_REQUEST = () => {
    return {
        type: types.UPDATE_SETTINGS_REQUEST,
    };
};

export const UPDATE_SETTINGS_SUCCESS = () => {
    return {
        type: types.UPDATE_SETTINGS_SUCCESS,
    };
};

export const UPDATE_SETTINGS_FAILURE = (error) => {
    return {
        type: types.UPDATE_SETTINGS_FAILURE,
        error: error,
    };
};

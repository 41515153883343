import {
    Form,
    Input,
    InputNumber,
    Modal,
    Checkbox,
    Select,
    DatePicker,
    TimePicker,
    Upload,
    message,
    Image as AntdImage,
    Space,
} from "antd";
import { UploadOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { Option } from "antd/lib/mentions";
import React, { useState, cloneElement, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from 'moment';
import config from "../../config";
import "./AddOrUpdateRecordModal.css";

const AddOrUpdateRecordModal = ({
    title,
    okText,
    cancelText,
    beforeOnClick,
    component,
    state = {},
    addRecord = {
        // callback: () => {},
    },
    updateRecord = {
        // record: {},
        // callback: () => {},
    },
    formSettings = [],
}) => {
    const dispatch = useDispatch();
    const [ visible, setVisible ] = useState(false);
    const { token } = useSelector(state => state.auth);
    const { query, page, pageSize } = state;
    const [ form ] = Form.useForm();
    const [ values, setValues ] = useState({});
    const [ convertedValues, setConvertedValues ] = useState({});
    const [ fileList, setFileList ] = useState({});
    const [ deletedFiles, setDeletedFiles ] = useState([]);
    const dateFormat = 'YYYY-MM-DD';
    const timeFormat = 'HH:mm';

    const tailLayout = {
        wrapperCol: { offet: 8, span: 16 },
    };
    
    const layout = {
        labelCol: { span: 8, style: { "whiteSpace": "normal" } },
        wrapperCol: { span: 16 },
    };

    const getObjectValue = useCallback((obj, key) => {
        if (!obj)
            return obj;

        const index = key.indexOf('.');
        if (index > -1)
            return getObjectValue(obj[key.substring(0, index)], key.substr(index + 1));

        return obj[key];
    }, []);

    const setObjectValue = useCallback((obj, key, value) => {
        if (!obj || obj.constructor !== Object)
            return;

        const index = key.indexOf('.');
        if (index > -1)
            return setObjectValue(obj[key.substring(0, index)], key.substr(index + 1), value);

        obj[key] = value;
    }, []);

    const deleteObjectKey = useCallback((obj, key) => {
        if (!obj || obj.constructor !== Object)
            return;

        const index = key.indexOf('.');
        if (index > -1)
            return deleteObjectKey(obj[key.substring(0, index)], key.substr(index + 1));

        delete obj[key];
    }, []);

    const convertMultiLevelObjectToSingleLevel = useCallback((value, previousKey) => {
        let convertedValue = {};

        if (!value || value.constructor !== Object)
            return { [previousKey]: value };

        Object.keys(value).forEach(key => {
            convertedValue = {
                ...convertedValue,
                ...convertMultiLevelObjectToSingleLevel(value[key], previousKey ? `${previousKey}.${key}` : key),
            };
        });

        return convertedValue;
    }, []);

    useEffect(() => {
        if (updateRecord.record) {
            let record = JSON.parse(JSON.stringify(updateRecord.record));
            let tempConvertedValue = {};
            let tempFileList = {};

            for (const item of formSettings) {
                const keyPath = item.dataKey?.constructor === Array ? item.dataKey.join(".") : item.dataKey;
                const value = getObjectValue(record, keyPath);

                if (!value) continue;

                if (item.type === 'image' && value) {
                    if (item?.multiple ?? true) {
                        let tempValue = [];
                        (value?.constructor === Array ? value : value ? [value] : []).forEach(image => {
                            tempValue.push({
                                name: image,
                                status: "done",
                                url: `${config.api_uri}${image}`,
                            });
                        });
                        setObjectValue(record, keyPath, tempValue);
                        tempFileList = {
                            ...tempFileList,
                            [keyPath]: tempValue,
                        };
                        tempConvertedValue = {
                            ...tempConvertedValue,
                            [keyPath]: tempValue,
                        };
                    } else {
                        let tempValue;
                        if (value?.constructor === Array && value.length) {
                            tempValue = {
                                name: value[0],
                                status: "done",
                                url: `${config.api_uri}${value[0]}`,
                            };
                        } else if (value) {
                            tempValue = {
                                name: value,
                                status: "done",
                                url: `${config.api_uri}${value}`,
                            };
                        }
                        setObjectValue(record, keyPath, tempValue ? [tempValue] : undefined);
                        tempFileList = {
                            ...tempFileList,
                            [keyPath]: tempValue ? [tempValue] : undefined,
                        };
                        tempConvertedValue = {
                            ...tempConvertedValue,
                            [keyPath]: tempValue,
                        };
                    }
                } else if (item.type === 'date') {
                    setObjectValue(record, keyPath, moment(value, dateFormat));
                    tempConvertedValue = {
                        ...tempConvertedValue,
                        [keyPath]: value,
                    };
                } else if (item.type === 'time') {
                    setObjectValue(record, keyPath, moment(value, timeFormat));
                    tempConvertedValue = {
                        ...tempConvertedValue,
                        [keyPath]: value,
                    };
                }
            }

            form.setFieldsValue(record);
            setConvertedValues(tempConvertedValue);
            setFileList(tempFileList);
            setValues(convertMultiLevelObjectToSingleLevel(updateRecord.record));
        }
    }, [
        visible,
        getObjectValue,
        setObjectValue,
        updateRecord.record,
        convertMultiLevelObjectToSingleLevel,
        form,
        formSettings,
    ]);

    const cloneElementAndModify = ({ component, props }) => {
        if (!component)
            return <></>;

        const element = cloneElement(
            component,
            props,
        );

        return element;
    };

    const openModal = () => {
        if (beforeOnClick && !beforeOnClick())
            return;
        setVisible(true);
    };

    const closeModal = () => {
        setVisible(false);
        resetForm();
    };

    const resetForm = () => {
        form.resetFields();
        setValues({});
        setConvertedValues({});
    };

    const onFinish = (values, form) => {
        Object.keys(convertedValues).forEach(key => {
            setObjectValue(values, key, convertedValues[key]);
        });

        if (deletedFiles?.length)
            values.deletedFiles = deletedFiles;

        if (addRecord.callback) {
            dispatch(addRecord.callback({
                values,
                page: 1,
                pageSize,
                token,
                query,
                closeModal,
            }));
        }

        if (updateRecord.callback) {
            dispatch(updateRecord.callback(
                updateRecord.record?._id,
                values,
                page,
                pageSize,
                token,
                query,
                closeModal,
            ));
        }
    };

    const checkRequiredValidator = type => (rule, value) => {
        if (['text', 'password'].includes(type) && value?.trim())
            return Promise.resolve();

        if (type === 'image' && value?.constructor === Array ? value?.length : value?.fileList?.length)
            return Promise.resolve();

        if (['number', 'checkbox', 'date', 'time', 'selectOption'].includes(type) && value !== null && value !== undefined)
            return Promise.resolve();

        return Promise.reject(`'${rule?.field}' is required`);
    };

    const checkNumberValidator = (rule, value) => {
        const regex = /[^0-9]/g;
        return regex.test(value) ? Promise.reject(`${rule?.field} can only enter numbers`) : Promise.resolve();
    };

    const getRules = (type, name, settings = {}) => {
        let results = [];

        if (settings?.required)
            results.push({
                required: true,
                validator: checkRequiredValidator(type),
                message: `Please ${
                    type === "image"
                    ? 'upload'
                    : ['date', 'time', 'selectOption'].includes(type)
                    ? "select"
                    : "input"
                } ${name}.`,
            });

        if (settings?.email)
            results.push({ type: 'email', message: 'Invalid email format.' });

        if (settings?.number && type !== 'number')
            results.push({ validator: checkNumberValidator });

        if ((settings?.min || settings?.min === 0) && ['text', 'password'].includes(type))
            results.push({ min: settings.min, message: `${name} must be at least ${settings.min} characters.` });

        if ((settings?.max || settings?.max === 0) && ['text', 'password'].includes(type))
            results.push({ max: settings.max, message: `${name} must be at most ${settings.max} characters.` });

        // note: custom validator must return Promise.resolve or Promise.reject
        if (settings.hasOwnProperty("customValidator") && settings.customValidator?.constructor === Function)
            results.push({ validator: (rule, value) => settings.customValidator(value) });

        return results;
    };

    const renderFormItems = (item) => {
        if (!item || !item.dataKey)
            return;

        if (item.condition) {
            const conditionField = item.condition?.field?.constructor === Array ? item.condition?.field.join(".") : item.condition?.field;
            const conditionValue = item.condition?.value;
            if (!conditionField)
                return;
            if ((conditionValue || conditionValue === false) && values[conditionField] !== conditionValue)
                return;
        }

        const dataKey = item.dataKey;
        const keyPath = item.dataKey?.constructor === Array ? item.dataKey.join(".") : item.dataKey;
        const label = item.label;
        const type = item.type;
        const initialValue = item.initialValue;

        if ((initialValue || initialValue === false) && !values.hasOwnProperty(keyPath)) {
            setValues({
                ...values,
                [keyPath]: initialValue,
            });
        }

        return <Form.Item
            {...tailLayout}
            name={dataKey}
            label={label ?? keyPath}
            rules={getRules(type, label ?? dataKey, item.rules) ?? []}
            initialValue={initialValue}
            valuePropName={item.type === "checkbox" ? "checked" : undefined}
        >
            {
                type === "text"
                ? <Input
                    disabled={updateRecord?.record && item?.updateDisabled}
                    onBlur={e => {
                        setValues({
                            ...values,
                            [keyPath]: e?.target?.value,
                        });
                    }}
                />
                : type === "password"
                ? <Input.Password
                    disabled={updateRecord?.record && item?.updateDisabled}
                    onBlur={e => {
                        console.log(e?.target?.value)
                        setValues({
                            ...values,
                            [keyPath]: e?.target?.value,
                        });
                    }}
                />
                : type === "number"
                ? <InputNumber
                    disabled={updateRecord?.record && item?.updateDisabled}
                    style={{ width: '100%' }}
                    min={item.min}
                    max={item.max}
                    onBlur={e => {
                        setValues({
                            ...values,
                            [keyPath]: e?.target?.value,
                        });
                    }}
                />
                : type === "checkbox"
                ? <Checkbox
                    disabled={updateRecord?.record && item?.updateDisabled}
                    onChange={e => {
                        setValues({
                            ...values,
                            [keyPath]: e?.target?.checked,
                        });
                    }}
                />
                : type === "date"
                ? <DatePicker
                    disabled={updateRecord?.record && item?.updateDisabled}
                    style={{ width: '100%' }}
                    onChange={(date, dateString) => {
                        setValues({
                            ...values,
                            [keyPath] : dateString,
                        });
                        setConvertedValues({
                            ...convertedValues,
                            [keyPath] : dateString,
                        });
                    }}
                />
                : type === "time"
                ? <TimePicker
                    disabled={updateRecord?.record && item?.updateDisabled}
                    style={{ width: "100%" }}
                    format={timeFormat}
                    onChange={(date, dateString) => {
                        setValues({
                            ...values,
                            [keyPath] : dateString,
                        });
                        setConvertedValues({
                            ...convertedValues,
                            [keyPath] : dateString,
                        });
                    }}
                />
                : type === "selectOption"
                ? <Select
                    disabled={updateRecord?.record && item?.updateDisabled}
                    onChange={value => {
                        setValues({
                            ...values,
                            [keyPath]: value,
                        });
                    }}
                    mode={item.multiple ? "multiple" : undefined}
                >
                    {(item.options?.constructor === Function ? item.options() : item.options)?.map(option => {
                        return (
                            <Option value={option.value}>
                                {option.label ?? option.value}
                            </Option>
                        );
                    })}
                </Select>
                : type === "image"
                ? <Upload
                    disabled={updateRecord?.record && item?.updateDisabled}
                    defaultFileList={fileList[keyPath] ?? []}
                    onChange={async e => {
                        // if (!e.file.url && !e.file.type?.includes("image/")) {
                        //     e.fileList.pop();
                        // }

                        let tempFileList = [];

                        const processFileList = async (index, file) => {
                            if (file.url)
                                return tempFileList.push(file);

                            await new Promise(resolve => {
                                let reader = new FileReader();

                                reader.onload = (e) => {
                                    file.base64 = e.target.result;
                                    if (item.multiple ?? true) {
                                        tempFileList.push({
                                            name: file.name,
                                            base64String: file.base64,
                                            type: file.type,
                                        });
                                    } else {
                                        tempFileList = [{
                                            name: file.name,
                                            base64String: file.base64,
                                            type: file.type,
                                        }];
                                    }
                                    resolve();
                                };

                                reader.onerror = err => {
                                    e.fileList.splice(index, 1);
                                    resolve();
                                }

                                reader.readAsDataURL(file.originFileObj);
                            });
                        };

                        for (let [index, file] of e.fileList.entries()) {
                            await processFileList(index, file);
                        }

                        setConvertedValues({
                            ...convertedValues,
                            [keyPath]: (item.multiple ?? true) ? tempFileList : tempFileList[0],
                        });
                    }}
                    onRemove={image => {
                        return new Promise(resolve => {
                            const { confirm } = Modal;
                            confirm({
                                icon: false,
                                title: <Space>
                                    <ExclamationCircleOutlined
                                        style={{
                                            color: "#f78e3d",
                                        }}
                                    />
                                    Are you sure to delete this image?
                                </Space>,
                                content: <AntdImage
                                    src={image?.url ?? image?.base64}
                                    alt={image?.name?.replace("/asset/images/", "")}
                                    preview={false}
                                />,
                                onOk: () => {
                                    if (image.url) {
                                        setDeletedFiles([
                                            ...deletedFiles,
                                            image.name,
                                        ]);
                                    }
                                    resolve(true);
                                },
                            });
                        });
                    }}
                    onPreview={async (file) => {
                        let src = file.url;
                        if (!src) {
                            src = await new Promise((resolve) => {
                                const reader = new FileReader();
                                reader.readAsDataURL(file.originFileObj);
                                reader.onload = () => resolve(reader.result);
                            });
                        }
                        const image = new Image();
                        image.src = src;
                        const imgWindow = window.open(src);
                        imgWindow.document.write(image.outerHTML);
                    }}
                    beforeUpload={file => {
                        const fileTypes = ["image/jpeg", "image/webp", "image/png"];
                        if (!fileTypes.includes(file.type)) {
                            message.error("Incorrect file type");
                            return Upload.LIST_IGNORE;
                        }
                        if (item.multiple === false && convertedValues[keyPath]) {
                            message.error(`${dataKey.constructor === Array ? dataKey[dataKey.length - 1] : dataKey} is not allowed multiple.`);
                            return Upload.LIST_IGNORE;
                        }
                        if ((item.multiple ?? true) && item.maxCount && convertedValues[keyPath]?.length >= item.maxCount) {
                            message.error(`Only up to ${item.maxCount} images can be uploaded.`);
                            return Upload.LIST_IGNORE;
                        }
                        return false;
                    }}
                    listType="picture-card"
                >
                    <div>
                        <UploadOutlined />
                        <div>Upload</div>
                    </div>
                </Upload>
                : <></>
            }
        </Form.Item>;
    };

    return (
        <>
            {cloneElementAndModify({
                component: component,
                props: {
                    onClick: openModal,
                },
            })}
            <Modal
                title={title ?? "Record"}
                visible={visible}
                onOk={() => {
                    form.validateFields().then((values) => {
                        onFinish(values, form);
                    });
                }}
                onCancel={closeModal}
                okText={okText ?? "Ok"}
                cancelText={cancelText ?? "Cancel"}
                destroyOnClose
            >
                <Form
                    {...layout}
                    name={title ?? "Record"}
                    form={form}
                >
                    {formSettings?.map(item => {
                        return renderFormItems(item);
                    })}
                </Form>
            </Modal>
        </>
    );
};

export default AddOrUpdateRecordModal;

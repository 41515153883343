import { message } from "antd";
import requests from "../../services/requests";
import { showUsers } from "../users/action";
import { logout } from "../auth/action";
import types from "./types";

export const resetPassword = (data, page, pageSize, token) => {
    return async (dispatch) => {
        try {
            dispatch(RESET_USER_PASSWORD_REQUEST());
            await requests.put(token, "/user/resetPassword", {
                username: data.username,
            });
            dispatch(RESET_USER_PASSWORD_SUCCESS());
            dispatch(showUsers(page, pageSize, token));
            message.success("Successfully reset user's password", 1);
        } catch (e) {
            dispatch(RESET_USER_PASSWORD_FAILURE(e.response.data.message));
            if (e.response.status === 401) {
                message.error("User is Unathorized");
                dispatch(logout());
            } else {
                if (e.response && e.response.data && e.response.data.message) {
                    message.error(e.response.data.message, 1);
                } else if (
                    e.response &&
                    e.response.data &&
                    e.response.data.error
                ) {
                    message.error(e.response.data.error, 1);
                } else {
                    message.error("Error!", 1);
                }
            }
        }
    };
};

export const RESET_USER_PASSWORD_REQUEST = () => {
    return {
        type: types.RESET_USER_PASSWORD_REQUEST,
    };
};

export const RESET_USER_PASSWORD_SUCCESS = () => {
    return {
        type: types.RESET_USER_PASSWORD_SUCCESS,
    };
};

export const RESET_USER_PASSWORD_FAILURE = (error) => {
    return {
        type: types.RESET_USER_PASSWORD_FAILURE,
        error: error,
    };
};

import types from "./types";

export const OPEN_UPDATE_ROLE_MODAL = () => {
    return {
        type: types.OPEN_UPDATE_ROLE_MODAL,
    };
};

export const CLOSE_UPDATE_ROLE_MODAL = () => {
    return {
        type: types.CLOSE_UPDATE_ROLE_MODAL,
    };
};

export const UPDATE_TITLE = (title) => {
    return {
        type: types.UPDATE_TITLE,
        title: title,
    };
};

export const UPDATE_SELECTED = (permissions) => {
    return {
        type: types.UPDATE_SELECTED,
        permissions: permissions,
    };
};

export const UPDATE_MODAL_ROLE = (role, permList) => {
    let selected = { ...permList, ...role.permissions };
    Object.keys(permList).forEach(key => {
        Object.keys(permList[key]).forEach(subkey => {
            if (selected[key] && !selected[key][subkey]) {
                selected[key][subkey] = false;
            }
        });
    });
    return {
        type: types.UPDATE_MODAL_ROLE,
        role: role,
        title: role.title,
        selected: selected,
    };
};

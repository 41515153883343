import { message } from "antd";
import requests from "../../services/requests";
import { showUsers } from "../users/action";
import types from "./types";

export const deleteUser = (data, page, pageSize, token) => {
    return async (dispatch) => {
        try {
            dispatch(DELETE_USER_REQUEST());
            await requests.post(token, "/user/delete", {
                username: data.username,
            });
            dispatch(DELETE_USER_SUCCESS());
            dispatch(showUsers(page, pageSize, token));
            message.success("Successfully deleted the user", 1);
        } catch (e) {
            dispatch(DELETE_USER_FAILURE(e.response.data.message));
            message.error(e.response.data.message, 1);
        }
    };
};

export const DELETE_USER_REQUEST = () => {
    return {
        type: types.DELETE_USER_REQUEST,
    };
};

export const DELETE_USER_SUCCESS = () => {
    return {
        type: types.DELETE_USER_SUCCESS,
    };
};

export const DELETE_USER_FAILURE = (error) => {
    return {
        type: types.DELETE_USER_FAILURE,
        error: error,
    };
};

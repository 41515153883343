import { Popconfirm, Space, Table, Tag, Tooltip } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    ExclamationCircleTwoTone,
    DeleteTwoTone,
    RedoOutlined,
} from "@ant-design/icons";
import { showUsers } from "../../store/users/action";
import { deleteUser } from "../../store/deleteUser/action";
import { resetPassword } from "../../store/resetPassword/action";

const UsersTable = () => {
    const dispatch = useDispatch();
    const { users, count, totalCount, page, pageSize } = useSelector((state) => state.users);
    const { token, permissions } = useSelector((state) => state.auth);
    const { Column } = Table;

    const handleTableChange = (pagination, filters, sorter) => {
        dispatch(showUsers(pagination.current, pagination.pageSize, token));
    };

    const getActionWidth = () => {
        let numOfAction = 0;
        if (permissions?.users?.resetPassword) numOfAction += 1;
        if (permissions?.users?.deleteUser) numOfAction += 1;
        return `${numOfAction > 1 ? numOfAction * 30 : 60}px`;
    };

    const handleDeleteUser = (text, record, index) => {
        if (count !== 1 || page === 1) {
            dispatch(deleteUser(record, page, pageSize, token));
        } else {
            dispatch(deleteUser(record, page-1, pageSize, token));
        }
    };

    const handleResetPassword = (text, record, index) => {
        dispatch(resetPassword(record, page, pageSize, token));
    };

    return (
        <Table
            dataSource={users}
            size="small"
            scroll={{ x: 2 * 200 }}
            pagination={{
                current: page,
                pageSize: pageSize,
                total: totalCount,
                showSizeChanger: true,
            }}
            onChange={handleTableChange}
        >
            <Column
                title="Username"
                dataIndex="username"
                key="username"
                align="center"
            />

            <Column
                title="Role"
                key="role"
                align="center"
                render={record => (
                    <Tag color={record.roleDeleted ? "red" : "geekblue"} key={record.role}>
                        {record.role}
                    </Tag>
                )}
            />

            {permissions?.users?.resetPassword || permissions?.users?.deleteUser ? (
                <Column
                    title="Action"
                    key="Action"
                    align="center"
                    width={getActionWidth()}
                    fixed="right"
                    render={(text, record, index) => (
                        <Space size="small">
                            {permissions?.users?.resetPassword? (
                                <Popconfirm
                                    title="Are you sure to reset password of this account?"
                                    onConfirm={() =>
                                        handleResetPassword(text, record, index)
                                    }
                                    okText="Reset"
                                    cancelText="Cancel"
                                    icon={
                                        <ExclamationCircleTwoTone twoToneColor="red" />
                                    }
                                >
                                    <Tooltip title={"Reset Password"}>
                                        <RedoOutlined
                                            style={{ color: "#1890FF" }}
                                        />
                                    </Tooltip>
                                </Popconfirm>
                            ) : (
                                <></>
                            )}

                            {permissions?.users?.deleteUser ? (
                                <Popconfirm
                                    title="Are you sure to delete this account?"
                                    onConfirm={() =>
                                        handleDeleteUser(text, record, index)
                                    }
                                    okText="Delete"
                                    cancelText="Cancel"
                                    icon={
                                        <ExclamationCircleTwoTone twoToneColor="red" />
                                    }
                                >
                                    <Tooltip title={"Delete"}>
                                        <DeleteTwoTone twoToneColor="#eb2f96" />
                                    </Tooltip>
                                </Popconfirm>
                            ) : (
                                <></>
                            )}
                        </Space>
                    )}
                />
            ) : (
                <></>
            )}
        </Table>
    );
};

export default UsersTable;

import React, { useEffect } from "react";
import { Breadcrumb, Button, Row } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { showUsers } from "../../store/users/action";
import UsersTable from "../../components/UsersTable/UsersTable";
import AddOrUpdateRecordModal from "../../components/AddOrUpdateRecordModal/AddOrUpdateRecordModal";
import { Redirect } from "react-router-dom";
import { showRoles } from "../../store/roles/action";
import { addUser } from "../../store/addUser/action";

const Users = () => {
    const dispatch = useDispatch();
    const { token, permissions } = useSelector(state => state.auth);
    const { formSettings, page, pageSize } = useSelector(state => state.users);

    useEffect(() => {
        dispatch(showUsers(1, 10, token));
        dispatch(showRoles("", "", token));
    }, [token, dispatch]);

    if (permissions && !permissions.users?.getUser) {
        return <Redirect to="/" />;
    }

    return (
        <>
            <Breadcrumb style={{ margin: "16px 0" }}>
                <Breadcrumb.Item>Show Users</Breadcrumb.Item>
            </Breadcrumb>
            <div
                className="site-layout-background"
                style={{ padding: 24, minHeight: 360 }}
            >
                {permissions?.users?.addUser ? (
                    <Row gutter={[0, 24]} style={{ paddingBottom: "1rem" }}>
                        <AddOrUpdateRecordModal
                            title = "Add User"
                            okText = "Submit"
                            component = {
                                <Button type="primary" style={{ borderRadius: 5 }}>Add</Button>
                            }
                            formSettings={formSettings}
                            addRecord={{
                                callback: addUser,
                            }}
                            state={{
                                page,
                                pageSize,
                            }}
                        />
                    </Row>
                ) : (
                    <></>
                )}
                <UsersTable />
            </div>
        </>
    );
};

export default Users;

import changePasswordTypes from "./types";

const initialState = {
    isLoading: false,
    error: null,
};

const changePasswordReducer = (state = initialState, action) => {
    switch (action.type) {
        case changePasswordTypes.CHANGE_PASSWORD_REQUEST:
            return { ...state, isLoading: true };
        case changePasswordTypes.CHANGE_PASSWORD_SUCCESS:
            return {
                ...state,
                isLoading: false,
                error: null,
            };
        case changePasswordTypes.CHANGE_PASSWORD_FAILURE:
            return { ...state, isLoading: false, error: action.error };
        default:
            return state;
    }
};

export default changePasswordReducer;

import { message } from "antd";
import requests from "../../services/requests";
import { logout } from "../auth/action";
import types from "./types";

export const changePassword = (FormData, form, token) => {
    return async (dispatch) => {
        let response;
        try {
            dispatch(CHANGE_PASSWORD_REQUEST());
            response = await requests.put(
                token,
                "/user/changePassword",
                FormData
            );
            dispatch(CHANGE_PASSWORD_SUCCESS());
            message.success(response.data.message, 1);
            form.resetFields();
        } catch (e) {
            if (e.response) {
                dispatch(CHANGE_PASSWORD_FAILURE(e.response.data.message));
                message.error(e.response.data.message, 1);
                if (e.response.status === 401) {
                    dispatch(logout());
                }
            }
        }
    };
};

export const CHANGE_PASSWORD_REQUEST = () => {
    return {
        type: types.CHANGE_PASSWORD_REQUEST,
    };
};

export const CHANGE_PASSWORD_SUCCESS = () => {
    return {
        type: types.CHANGE_PASSWORD_SUCCESS,
    };
};

export const CHANGE_PASSWORD_FAILURE = (error) => {
    return {
        type: types.CHANGE_PASSWORD_FAILURE,
        error,
    };
};

import React, { useEffect } from "react";
import { Layout, Menu, Dropdown, Space, Avatar } from 'antd';
import { UserOutlined } from "@ant-design/icons";
import { Link, Switch, Route, Redirect } from "react-router-dom";
import "./App.css";
import { useDispatch, useSelector } from "react-redux";
import { VALIDATE_TOKEN, logout } from "./store/auth/action";
import store from "./store/store";

import MainMenu from "./components/MainMenu/MainMenu";
import SignIn from "./pages/SignIn/SignIn";
import ChangePassword from "./pages/ChangePassword/ChangePassword";
import Settings from "./pages/Settings/Settings";
import Users from "./pages/Users/Users";
import Roles from "./pages/Roles/Roles";
import ActionLog from "./pages/ActionLog/ActionLog";
import Task from "./pages/Task/Task";
import Result from "./pages/Result/Result";

const { Header, Content } = Layout;

function App() {
  const dispatch = useDispatch();
  const loggedIn = useSelector((state) => state.auth.token);
  const permissions = useSelector((state) => state.auth.permissions);
  let username;
  if(store.getState().auth && store.getState().auth.user){
    username = store.getState().auth.user.username;
  }

  useEffect(() => {
    if(loggedIn){
      dispatch(VALIDATE_TOKEN(loggedIn));
    }
  }, [loggedIn, dispatch]);

  const handleLogout = () => {
    dispatch(logout(loggedIn));
  };

  const userMenu = (
    <Menu>
      {permissions?.users?.getUser ? (
        <Menu.Item key="user">
          <Link to="/user">Users</Link>
        </Menu.Item>
      ) : (
        <></>
      )}

      {permissions?.roles?.getRole ? (
        <Menu.Item key="role">
          <Link to="/role">Role</Link>
        </Menu.Item>
      ) : (
        <></>
      )}

      {permissions?.users?.changePassword ? (
        <Menu.Item key="changepassword">
          <Link to="/changepassword">Change Password</Link>
        </Menu.Item>
      ) : (
        <></>
      )}

      {permissions?.settings?.getSettings ? (
        <Menu.Item key="settings">
          <Link to="/settings">Settings</Link>
        </Menu.Item>
      ) : (
        <></>
      )}

      {permissions?.actionLog?.getActionLog ? (
        <Menu.Item key="actionlog">
          <Link to="/actionlog">Action Log</Link>
        </Menu.Item>
      ) : (
        <></>
      )}

      <Menu.Item key="logout" onClick={handleLogout}>
        <Link to="/signin">Logout</Link>
      </Menu.Item>
    </Menu>
  );

  return (
    <Layout className="App">
      {loggedIn && (
        <Header
          style={{
            padding: 0,
            textAlign: "end",
            fontWeight: 800,
          }}
        >
          <Dropdown overlay={userMenu} className="header-user">
            <Space size="small">
              {username}
              <Avatar icon={<UserOutlined />} />
            </Space>
          </Dropdown>
        </Header>
      )}
      <Layout>
        {loggedIn && (
          <MainMenu />
        )}

        <Content
          style={{
            padding: 24,
            minHeight: "100vh",
            justifyContent: "start",
          }}
        >
          <Switch>
            <Route exact path="/">
              {loggedIn ? (
                permissions?.task?.getTask ? (
                  <Redirect to="/task" />
                ) : (
                  <></>
                )
              ) : (
                <Redirect to="/signin" />
              )}
            </Route>
            <Route path="/signin" component={SignIn} />
            <Route path="/changepassword">
              {loggedIn ? (
                <ChangePassword />
              ) : (
                <Redirect to="/signin" />
              )}
            </Route>
            <Route path="/user">
              {loggedIn ? (
                <Users />
              ) : (
                <Redirect to="/signin" />
              )}
            </Route>
            <Route path="/role">
              {loggedIn ? (
                <Roles />
              ) : (
                <Redirect to="/signin" />
              )}
            </Route>
            <Route path="/settings">
              {loggedIn ? (
                <Settings />
              ) : (
                <Redirect to="/signin" />
              )}
            </Route>
            <Route path="/actionlog">
              {loggedIn ? (
                <ActionLog />
              ) : (
                <Redirect to="/signin" />
              )}
            </Route>
            <Route path="/task">
              {loggedIn ? (
                <Task />
              ) : (
                <Redirect to="/signin" />
              )}
            </Route>
            <Route path="/result">
              {loggedIn ? (
                <Result />
              ) : (
                <Redirect to="/signin" />
              )}
            </Route>
            <Route path="*">
              <Redirect to="/" />
            </Route>
          </Switch>
        </Content>
      </Layout>
    </Layout>
  );
}

export default App;

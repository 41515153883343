import addRoleTypes from "./types";

const initialState = {
    isLoading: false,
    error: null,
    selectedPermissions: {},
};

function addRoleReducer(state = initialState, action) {
    switch (action.type) {
        case addRoleTypes.ADD_ROLE_REQUEST:
            return { ...state, isLoading: true };
        case addRoleTypes.ADD_ROLE_SUCCESS:
            return { ...state, isLoading: false };
        case addRoleTypes.ADD_ROLE_FAILURE:
            return { ...state, isLoading: false, error: action.error };
        case addRoleTypes.SET_ADD_PERMISSIONS:
            return { ...state, selectedPermissions: action.permissions };
        default:
            return state;
    }
}

export default addRoleReducer;

import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { Layout, Menu } from "antd";

const { Sider } = Layout;

const MainMenu = () => {
    const [collapsed, setCollapse] = useState(false);
    const { permissions } = useSelector((state) => state.auth);
    const location = useLocation().pathname.split('/')[1];

    const onCollapse = () => {
        setCollapse(!collapsed);
    };

    return (
        <Sider
            collapsible
            collapsed={collapsed}
            onCollapse={onCollapse}
        >
            <Menu
                theme="dark"
                mode="inline"
                selectedKeys={[ location ]}
            >
                <Menu.ItemGroup>
                    {permissions?.task?.getTask ? (
                        <Menu.Item key="task">
                            <Link to="/task">Task</Link>
                        </Menu.Item>
                    ) : (
                        <></>
                    )}
                    {permissions?.result?.getResult ? (
                        <Menu.Item key="result">
                            <Link to="/result">Result</Link>
                        </Menu.Item>
                    ) : (
                        <></>
                    )}
                </Menu.ItemGroup>
            </Menu>
        </Sider>
    );
};

export default MainMenu;

import updateRoleTypes from "./types";

const initialState = {
    isLoading: false,
    error: null,
};

function updateRoleReducer(state = initialState, action) {
    switch (action.type) {
        case updateRoleTypes.UPDATE_ROLE_REQUEST:
            return { ...state, isLoading: true };
        case updateRoleTypes.UPDATE_ROLE_SUCCESS:
            return { ...state, isLoading: false };
        case updateRoleTypes.UPDATE_ROLE_FAILURE:
            return { ...state, isLoading: false, error: action.error };
        default:
            return state;
    }
}

export default updateRoleReducer;

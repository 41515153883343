import { message } from "antd";
import requests from "../../services/requests";
import { showTask } from "../task/action";
import types from "./types";

export const updateTask = (id, newData, page, pageSize, token, query, resetState) => {
    return async (dispatch) => {
        try {
            dispatch(UPDATE_TASK_REQUEST());
            await requests.put(token, "/task/update", {
                id: id,
                newData: newData
            });
            dispatch(UPDATE_TASK_SUCCESS());
            dispatch(showTask(
                query,
                page,
                pageSize,
                token
            ));
            resetState();
            message.success("Successfully updated task", 1);
        } catch (e) {
            dispatch(UPDATE_TASK_FAILURE(e.response.data.message));
            message.error(e?.response?.data?.message ?? "Update task failure.", 1);
        }
    };
};

export const UPDATE_TASK_REQUEST = () => {
    return {
        type: types.UPDATE_TASK_REQUEST,
    };
};

export const UPDATE_TASK_SUCCESS = () => {
    return {
        type: types.UPDATE_TASK_SUCCESS,
    };
};

export const UPDATE_TASK_FAILURE = (error) => {
    return {
        type: types.UPDATE_TASK_FAILURE,
        error: error,
    };
};

import userTypes from "./types";
import store from "../store";

const initialState = {
    isLoading: false,
    users: [],
    count: 1,
    totalCount: 1,
    page: 1,
    pageSize: 10,
    formSettings: [
        {
            label: "Username",
            dataKey: "username",
            type: "text",
            rules: {
                required: true,
            },
        },
        {
            label: "Password",
            dataKey: "password",
            type: "password",
            rules: {
                required: true,
            },
        },
        {
            label: "Role",
            dataKey: "role",
            type: "selectOption",
            options: () => {
                const { roles = [] } = store?.getState()?.roles;
                return roles.map(role => ({
                    label: `${role.title.charAt(0).toUpperCase()}${role.title.slice(1)}`,
                    value: role.title
                }));
            },
            rules: {
                required: true,
            },
        },
    ],
};

function userReducer(state = initialState, action) {
    switch (action.type) {
        case userTypes.SHOW_USER_REQUEST:
            return { ...state, isLoading: true };
        case userTypes.SHOW_USER_SUCCESS:
            return {
                ...state,
                isLoading: false,
                users: action.user,
                count: action.count,
                totalCount: action.totalCount,
                page: action.page,
                pageSize: action.pageSize,
            };
        case userTypes.SHOW_USER_FAILURE:
            return { ...state, isLoading: false, error: action.error };
        default:
            return state;
    }
}

export default userReducer;

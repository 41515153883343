import { message } from "antd";
import requests from "../../services/requests";
import types from "./types";

export const login = (FormData) => {
    return async (dispatch) => {
        let response;
        try {
            dispatch(LOGIN_REQUEST());
            response = await requests.post(null, "/auth/login", FormData);
            const { user, token, permissions, permList } = response.data;
            dispatch(LOGIN_SUCCESS(user, token, permissions, permList));
            message.success(response.data.message, 1);
        } catch (e) {
            dispatch(LOGIN_FAILURE(e.response.data.message));
            message.error(e.response.data.message, 1);
        }
    };
};

export const LOGIN_REQUEST = () => {
    return {
        type: types.LOGIN_REQUEST,
    };
};

export const LOGIN_SUCCESS = (user, token, permissions, permList) => {
    localStorage.setItem("user", JSON.stringify(user));
    localStorage.setItem("token", token);
    return {
        type: types.LOGIN_SUCCESS,
        user,
        token,
        permissions,
        permList: permList,
    };
};

export const LOGIN_FAILURE = (error) => {
    return {
        type: types.LOGIN_FAILURE,
        error,
    };
};

export const logout = (token) => {
    return async (dispatch) => {
        try {
            dispatch(LOGOUT_REQUEST());
            if (token) {
                requests.post(null, "/auth/logout", { token: token });
            }
            dispatch(LOGOUT_SUCCESS());
        } catch (e) {
            dispatch(LOGOUT_FAILURE());
        }
    };
};

export const LOGOUT_REQUEST = () => {
    return {
        type: types.LOGOUT_REQUEST,
    };
};

export const LOGOUT_SUCCESS = () => {
    localStorage.clear();
    return {
        type: types.LOGOUT_SUCCESS,
    };
};

export const LOGOUT_FAILURE = () => {
    return {
        type: types.LOGOUT_FAILURE,
    };
};

export const VALIDATE_TOKEN = (token) => {
    return async (dispatch) => {
        let response;
        try {
            response = await requests.post(null, "/auth/validatetoken", {
                token: token,
            });
            const { notExpired, permissions, user, permList } = response.data;
            if (!notExpired) {
                localStorage.clear();
                dispatch(logout());
            } else {
                dispatch(LOGIN_SUCCESS(user, token, permissions, permList));
            }
        } catch (e) {
            console.error('validate token error:', e);
            dispatch(logout());
        }
    };
};

export const UPDATE_AUTH_USER_PERMISSIONS = (role, permissions) => {
    return {
        type: types.UPDATE_AUTH_USER_PERMISSIONS,
        role: role,
        permissions: permissions,
    };
};

export const CHANGE_COUNTRY = (country) => {
    return {
        type: types.CHANGE_COUNTRY,
        country: country,
    };
};

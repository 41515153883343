import resultTypes from "./types";
import store from "../store";
import { showResultLog } from "../resultLog/action";

const initialState = {
    isLoading: false,
    error: null,
    result: [],
    query: {},
    count: 1,
    totalCount: 1,
    page: 1,
    pageSize: 10,
    searchFormSettings: [
        {
            name: "Task Name",
            type: "text",
            dataKey: "taskName",
        },
        {
            name: "Username",
            type: "text",
            dataKey: "username",
        },
        {
            name: "Current Level",
            type: "text",
            dataKey: "currentLevel",
        },
        {
            name: "Next Level",
            type: "text",
            dataKey: "nextLevel",
        },
        {
            name: "Start Time",
            type: "dateRange",
            dataKey: "startTime",
        },
        {
            name: "End Time",
            type: "dateRange",
            dataKey: "endTime",
        },
        // 0 - pending, 1 - success, 2 - failed, 3 - inProgress, 4 - onHold, 5 - botInterrupted
        {
            name: "Status",
            dataKey: "status",
            type: "selectOption",
            selectOption: [
                {
                    name: "Pending",
                    value: 0
                },
                {
                    name: "Success",
                    value: 1
                },
                {
                    name: "Failed",
                    value: 2
                },
                {
                    name: "In Progress",
                    value: 3
                },
                {
                    name: "On Hold",
                    value: 4
                },
                {
                    name: "Bot Interrupted",
                    value: 5
                },
            ],
        },
    ],
    tableSettings: [
        {
            name: "Task Name",
            dataKey: ["taskObjId", "taskName"],
        },
        {
            name: "Username",
            dataKey: "username",
        },
        {
            name: "Current Level",
            dataKey: "currentLevel",
            excludeDefaultSelectedColumn: true,
        },
        {
            name: "Next Level",
            dataKey: "nextLevel",
            excludeDefaultSelectedColumn: true,
        },
        {
            name: "Result",
            hidden: () => {
                const { permissions } = store?.getState()?.auth;
                return !permissions?.result?.getResultLog;
            },
            popupModal: {
                showRecord: showResultLog,
                defaultQuery: (_, record) => {
                    return { resultObjId: record._id };
                },
                searchFormFixedQuery: (_, record) => {
                    return { resultObjId: record._id };
                },
                searchFormSettings: () => {
                    return store?.getState()?.resultLog?.searchFormSettings;
                },
                tableSettings: () => {
                    return store?.getState()?.resultLog?.tableSettings;
                },
                state: () => {
                    const {
                        resultLog,
                        query,
                        totalCount,
                        page,
                        pageSize,
                    } = store?.getState()?.resultLog;

                    return ({
                        records: resultLog,
                        query,
                        totalCount,
                        page,
                        pageSize,
                    });
                },
            },
        },
        {
            name: "Start Time",
            dataKey: "startTime",
            convertToDate: true,
        },
        {
            name: "End Time",
            dataKey: "endTime",
            convertToDate: true,
        },
        {
            name: "Status",
            dataKey: "status",
            convertToTag: {
                0: { color: "blue", text: "Pending" },
                1: { color: "green", text: "Success" },
                2: { color: "red", text: "Failed" },
                3: { color: "blue", text: "In Progress" },
                4: { color: "volcano", text: "On Hold" },
                5: { color: "red", text: "Bot Interrupted" },
                default: { color: "warning", text: "Unknown" },
            },
        },
    ],
};

function resultReducer(state = initialState, action) {
    switch (action.type) {
        case resultTypes.SHOW_RESULT_REQUEST:
            return { ...state, isLoading: true };
        case resultTypes.SHOW_RESULT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                result: action.result,
                query: action.query,
                count: action.count,
                totalCount: action.totalCount,
                page: action.page,
                pageSize: action.pageSize,
            };
        case resultTypes.SHOW_RESULT_FAILURE:
            return { ...state, isLoading: false, error: action.error };
        default:
            return state;
    }
}

export default resultReducer;

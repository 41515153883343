import requests from "../../services/requests";
import { logout } from "../auth/action";
import types from "./types";

export const showSettings = (token) => {
    return async (dispatch) => {
        let response;
        try {
            dispatch(SHOW_SETTINGS_REQUEST());
            response = await requests.get(token, "/settings/all");
            dispatch(SHOW_SETTINGS_SUCCESS(response.data));
        } catch (e) {
            if (e.response) {
                dispatch(SHOW_SETTINGS_FAILURE(e));
                if (e.response.status === 401) {
                    dispatch(logout());
                }
            }
        }
    };
};

export const SHOW_SETTINGS_REQUEST = () => {
    return {
        type: types.SHOW_SETTINGS_REQUEST,
    };
};

export const SHOW_SETTINGS_SUCCESS = (settings) => {
    return {
        type: types.SHOW_SETTINGS_SUCCESS,
        settings: settings.data,
    };
};

export const SHOW_SETTINGS_FAILURE = (error) => {
    return {
        type: types.SHOW_SETTINGS_FAILURE,
        error: error,
    };
};

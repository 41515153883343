import { message } from "antd";
import requests from "../../services/requests";
import { logout, UPDATE_AUTH_USER_PERMISSIONS } from "../auth/action";
import { showRoles } from "../roles/action";
import { CLOSE_UPDATE_ROLE_MODAL } from "../updateRoleModal/action";
import store from "../../store/store";
import types from "./types";

export const updateRole = (
    oldTitle,
    newTitle,
    permissions,
    page,
    pageSize,
    token
) => {
    return async (dispatch) => {
        try {
            dispatch(UPDATE_ROLE_REQUEST());
            await requests.put(token, "/role/updatePermission", {
                oldTitle: oldTitle,
                newTitle: newTitle,
                permissions: permissions,
            });
            dispatch(UPDATE_ROLE_SUCCESS());
            dispatch(showRoles(page, pageSize, token));
            dispatch(CLOSE_UPDATE_ROLE_MODAL());
            if (oldTitle === store.getState().auth.role) {
                dispatch(
                    UPDATE_AUTH_USER_PERMISSIONS(newTitle, permissions)
                );
            }
            message.success("Successfully upated role's permission", 1);
        } catch (e) {
            if (e.response) {
                dispatch(UPDATE_ROLE_FAILURE(e));
                if (e.response.status === 401) {
                    message.error("Account is Unathorized");
                    dispatch(logout());
                } else {
                    if (
                        e.response &&
                        e.response.data &&
                        e.response.data.response &&
                        e.response.data.response.message
                    ) {
                        message.error(e.response.data.message, 1);
                    } else if (e.response.data && e.response.data.message) {
                        message.error(e.response.data.message, 1);
                    } else if (
                        e.response &&
                        e.response.data &&
                        e.response.data.error
                    ) {
                        message.error(e.response.data.error, 1);
                    } else {
                        message.error("Error!", 1);
                    }
                }
            }
        }
    };
};

export const UPDATE_ROLE_REQUEST = () => {
    return {
        type: types.UPDATE_ROLE_REQUEST,
    };
};

export const UPDATE_ROLE_SUCCESS = () => {
    return {
        type: types.UPDATE_ROLE_SUCCESS,
    };
};

export const UPDATE_ROLE_FAILURE = (error) => {
    return {
        type: types.UPDATE_ROLE_FAILURE,
        error: error,
    };
};

import React from "react";
import { Form, Input, Button, Card, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { changePassword } from "../../store/changePassword/action";
import { Redirect } from "react-router-dom";

const layout = {
    wrapperCol: { span: 24 },
};

const ChangePassword = () => {
    const dispatch = useDispatch();
    const { token, permissions } = useSelector((state) => state.auth);
    const [form] = Form.useForm();

    const onFinish = (values) => {
        if (values.newPassword !== values.confirmPassword) {
            return message.error("New password and Confirm password do not match. please retype.");
        }

        if (values.hasOwnProperty("confirmPassword")) delete values["confirmPassword"];

        dispatch(changePassword(values, form, token));
    };

    if (permissions && !permissions.users?.changePassword) {
        return <Redirect to="/" />;
    }

    return (
        <div
            className="site-layout-background"
            style={{
                paddingTop: "5rem",
                minHeight: 360,
                position: "relative",
            }}
        >
            <Card
                title="Change Password"
                style={{
                    borderRadius: 10,
                    display: "block",
                    paddingT: "2rem",
                    marginLeft: "auto",
                    marginRight: "auto",
                    width: "60%",
                }}
            >
                <h4 style={{ paddingBottom: "1rem" }}>
                    Enter a new password for your account.
                </h4>
                <Form
                    {...layout}
                    name="Change Password"
                    initialValues={{ remember: true }}
                    onFinish={onFinish}
                    form={form}
                >
                    <Form.Item
                        name="password"
                        rules={[
                            {
                                required: true,
                                message: "Please input your password!",
                            },
                        ]}
                    >
                        <Input.Password placeholder="Old Password" />
                    </Form.Item>

                    <Form.Item
                        name="newPassword"
                        rules={[
                            {
                                required: true,
                                message: "Please input your new password!",
                            },
                        ]}
                    >
                        <Input.Password placeholder="New Password" />
                    </Form.Item>

                    <Form.Item
                        name="confirmPassword"
                        rules={[
                            {
                                required: true,
                                message: "Please input your confirm password!",
                            },
                        ]}
                    >
                        <Input.Password placeholder="Confirm Password" />
                    </Form.Item>

                    <Button type="primary" htmlType="submit">
                        Submit
                    </Button>
                </Form>
            </Card>
        </div>
    );
};

export default ChangePassword;

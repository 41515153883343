import authTypes from "./types";
import config from "../../config";
const user = localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user"))
    : null;
const token = localStorage.getItem("token")
    ? localStorage.getItem("token")
    : null;

const initialState = {
    isLoading: false,
    user: user,
    token: token,
    error: null,
    permissions: null,
    permList: null,
    country: config.defaultCountry,
    supportedCountry: config.supportedCountry,
};

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case authTypes.LOGIN_REQUEST:
            return { ...state, isLoading: true };
        case authTypes.LOGIN_SUCCESS:
            return {
                ...state,
                isLoading: false,
                user: action.user,
                token: action.token,
                permissions: action.permissions,
                permList: action.permList,
                error: null,
            };
        case authTypes.LOGIN_FAILURE:
            return { ...state, isLoading: false, error: action.error };
        case authTypes.LOGOUT_REQUEST:
            return { ...state, isLoading: true };
        case authTypes.LOGOUT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                user: null,
                token: null,
                error: null,
                permissions: null,
                permList: {},
                country: config.defaultCountry,
                supportedCountry: config.supportedCountry,
            };
        case authTypes.LOGOUT_FAILURE:
            return { ...state, isLoading: false, error: action.error };
        case authTypes.VALIDATE_TOKEN:
            return { ...state };
        case authTypes.UPDATE_AUTH_USER_PERMISSIONS:
            return { ...state, permissions: action.permissions };
        case authTypes.CHANGE_COUNTRY:
            return { ...state, country: action.country };
        default:
            return state;
    }
};

export default authReducer;

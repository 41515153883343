import addTaskTypes from "./types";

const initialState = {
    isLoading: false,
    error: null,
};

function addTaskReducer(state = initialState, action) {
    switch (action.type) {
        case addTaskTypes.ADD_TASK_REQUEST:
            return { ...state, isLoading: true };
        case addTaskTypes.ADD_TASK_SUCCESS:
            return { ...state, isLoading: false };
        case addTaskTypes.ADD_TASK_FAILURE:
            return { ...state, isLoading: false, error: action.error };
        default:
            return state;
    }
}

export default addTaskReducer;

import addUserTypes from "./types";

const initialState = {
    isLoading: false,
    error: null,
};

function addUserReducer(state = initialState, action) {
    switch (action.type) {
        case addUserTypes.ADD_USER_REQUEST:
            return { ...state, isLoading: true };
        case addUserTypes.ADD_USER_SUCCESS:
            return { ...state, isLoading: false };
        case addUserTypes.ADD_USER_FAILURE:
            return { ...state, isLoading: false, error: action.error };
        default:
            return state;
    }
}

export default addUserReducer;

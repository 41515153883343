import resetPasswordTypes from "./types";

const initialState = {
    isLoading: false,
    error: null,
};

function resetPasswordReducer(state = initialState, action) {
    switch (action.type) {
        case resetPasswordTypes.RESET_USER_PASSWORD_REQUEST:
            return { ...state, isLoading: true };
        case resetPasswordTypes.RESET_USER_PASSWORD_SUCCESS:
            return { ...state, isLoading: false };
        case resetPasswordTypes.RESET_USER_PASSWORD_FAILURE:
            return { ...state, isLoading: false, error: action.error };
        default:
            return state;
    }
}

export default resetPasswordReducer;

import React, { useState } from "react";
import {
    Form,
    Card,
    Row,
    Col,
    Button,
    Space,
    Popconfirm,
    Tooltip,
    // message,
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import {
    ExclamationCircleTwoTone,
    QuestionCircleOutlined,
} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { updateSettings } from "../../store/updateSettings/action";

const UpdateNotificationSettings = () => {
    const dispatch = useDispatch();
    const { permissions, token } = useSelector((state) => state.auth);
    const { settings } = useSelector((state) => state.settings);
    const [ form ] = Form.useForm();
    const [ editEnvironment, setEditEnvironment ] = useState(false);
    const arrayFields = [
        // "email",
        "gchatTaggingUsers",
    ];

    const tailLayout = {
        wrapperCol: { offet: 12, span: 12},
    };
    
    const layout = {
        labelCol: { span: 6, style: {"whiteSpace": "normal"} },
        wrapperCol: { span: 18 },
    };

    if (settings?.notification) {
        arrayFields.forEach(field => {
            if (settings?.notification?.[field]?.constructor === Array) {
                settings.notification[field] = settings.notification[field].join("\n");
            }
        });
        form.setFieldsValue(settings.notification);
    }

    const onFinish = (values) => {
        arrayFields.forEach(field => {
            if (values[field]) {
                values[field] = values[field].trim();
                values[field] = values[field].replace(/\s\s+/g, ' ');
                values[field] = values[field].split(/\n| /);
            } else {
                values[field] = [];
            }
        });

        // for (const email of values.email) {
        //     if (!/\S+@\S+\.\S+/.test(email)) {
        //         return message.error(`Email: ${email} is invalid.`);
        //     }
        // }
        dispatch(updateSettings(
            { notification: values },
            token,
            resetState,
        ));
    };

    const onCancel = () => {
        form.resetFields();
        form.setFieldsValue(settings?.notification);
        resetState();
    };

    const resetState = () => {
        setEditEnvironment(false);
    };
    
    return (
        <>
            <Row gutter={[0, 24]} style={{ paddingBottom: "1rem" }}>
                <strong>Malfunction Notification</strong>
            </Row>
            <Card>
                <Form
                    {...layout}
                    name="Update Notification Setting"
                    onFinish={() => {
                        form.validateFields().then(values => {
                            onFinish(values);
                        })
                    }}
                    form={form}
                >
                    {/* <Form.Item
                        {...tailLayout}
                        label={
                            <Space>
                                Email
                                <Tooltip
                                    title={`Each email is separated by the next line`}
                                >
                                    <QuestionCircleOutlined
                                        style={{
                                            color: "blue"
                                        }}
                                    />
                                </Tooltip>
                            </Space>
                        }
                        name="email"
                    >
                        <TextArea
                            disabled={!editEnvironment}
                            autoSize
                        />
                    </Form.Item> */}

                    <Form.Item
                        {...tailLayout}
                        label={
                            <Space>
                                GChat Tagging Users
                                <Tooltip
                                    title={`Each user is separated by the next line`}
                                >
                                    <QuestionCircleOutlined
                                        style={{
                                            color: "blue"
                                        }}
                                    />
                                </Tooltip>
                            </Space>
                        }
                        name="gchatTaggingUsers"
                    >
                        <TextArea
                            disabled={!editEnvironment}
                            autoSize
                        />
                    </Form.Item>

                    {permissions?.settings?.editSettings ? (
                        <Row justify="end">
                            {editEnvironment ? (
                                <>
                                    <Col style={{ paddingRight: "1rem" }}>
                                        <Popconfirm
                                            title="Are you sure to cancel all changes?"
                                            onConfirm={onCancel}
                                            okText="Ok"
                                            cancelText="Cancel"
                                            icon={
                                                <ExclamationCircleTwoTone twoToneColor="red" />
                                            }
                                        >
                                            <Button
                                                size="small"
                                                style={{
                                                    borderRadius: 5,
                                                    backgroundColor: "red",
                                                    color: "white"
                                                }}
                                            >
                                                Cancel
                                            </Button>
                                        </Popconfirm>
                                    </Col>
                                    <Col>
                                        <Button
                                            type="primary"
                                            htmlType="submit"
                                            size="small"
                                            style={{
                                                borderRadius: 5,
                                            }}
                                        >
                                            Save
                                        </Button>
                                    </Col>
                                </>
                            ) : (
                                <Col>
                                    <Button
                                        type="primary"
                                        size="small"
                                        style={{
                                            borderRadius: 5,
                                        }}
                                        onClick={() => setEditEnvironment(true)}
                                    >
                                        Edit
                                    </Button>
                                </Col>
                            )}
                        </Row>
                    ) : (
                        <></>
                    )}
                </Form>
            </Card>
        </>
    );
};

export default UpdateNotificationSettings;

import requests from "../../services/requests";
import { logout } from "../auth/action";
import types from "./types";

export const showUsers = (page, pageSize, token) => {
    return async (dispatch) => {
        let response;
        const options = { page: page, pageSize: pageSize };
        try {
            dispatch(SHOW_USER_REQUEST());
            response = await requests.get(token, "/user/all", options);
            dispatch(SHOW_USER_SUCCESS(response.data));
        } catch (e) {
            if (e.response) {
                dispatch(SHOW_USER_FAILURE(e));
                if (e.response.status === 401) {
                    dispatch(logout());
                }
            }
        }
    };
};

export const SHOW_USER_REQUEST = () => {
    return {
        type: types.SHOW_USER_REQUEST,
    };
};

export const SHOW_USER_SUCCESS = (user) => {
    return {
        type: types.SHOW_USER_SUCCESS,
        user: user.data,
        count: user.count,
        totalCount: user.totalCount,
        page: user.page,
        pageSize: user.pageSize,
    };
};

export const SHOW_USER_FAILURE = (error) => {
    return {
        type: types.SHOW_USER_FAILURE,
        error: error,
    };
};

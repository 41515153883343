import addRoleModalTypes from "./types";

const initialState = {
    visible: false,
};

function addRoleModalReducer(state = initialState, action) {
    switch (action.type) {
        case addRoleModalTypes.OPEN_ADD_ROLE_MODAL:
            return { ...state, visible: true };
        case addRoleModalTypes.CLOSE_ADD_ROLE_MODAL:
            return { ...state, visible: false };
        default:
            return state;
    }
}

export default addRoleModalReducer;

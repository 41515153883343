import updateSettingsTypes from "./types";

const initialState = {
    isLoading: false,
    error: null,
};

function updateSettingsReducer(state = initialState, action) {
    switch (action.type) {
        case updateSettingsTypes.UPDATE_SETTINGS_REQUEST:
            return { ...state, isLoading: true };
        case updateSettingsTypes.UPDATE_SETTINGS_SUCCESS:
            return { ...state, isLoading: false };
        case updateSettingsTypes.UPDATE_SETTINGS_FAILURE:
            return { ...state, isLoading: false, error: action.error };
        default:
            return state;
    }
}

export default updateSettingsReducer;

import { message } from "antd";
import requests from "../../services/requests";
import { showTask } from "../task/action";
import types from "./types";

export const deleteTask = (data, page, pageSize, token, query) => {
    return async (dispatch) => {
        try {
            dispatch(DELETE_TASK_REQUEST());
            await requests.post(token, "/task/delete", {
                id: data._id,
            });
            dispatch(DELETE_TASK_SUCCESS());
            dispatch(showTask(
                query,
                page,
                pageSize,
                token,
            ));
            message.success("Successfully deleted the task", 1);
        } catch (e) {
            dispatch(DELETE_TASK_FAILURE(e.response.data.message));
            message.error(e.response.data.message, 1);
        }
    };
};

export const DELETE_TASK_REQUEST = () => {
    return {
        type: types.DELETE_TASK_REQUEST,
    };
};

export const DELETE_TASK_SUCCESS = () => {
    return {
        type: types.DELETE_TASK_SUCCESS,
    };
};

export const DELETE_TASK_FAILURE = (error) => {
    return {
        type: types.DELETE_TASK_FAILURE,
        error: error,
    };
};

import requests from "../../services/requests";
import { logout } from "../auth/action";
import types from "./types";

export const showTask = (query, page, pageSize, token) => {
    return async (dispatch) => {
        let response;
        const options = { query: query, page: page, pageSize: pageSize };
        try {
            dispatch(SHOW_TASK_REQUEST());
            response = await requests.get(token, "/task/all", options);
            dispatch(SHOW_TASK_SUCCESS(response.data));
        } catch (e) {
            if (e.response) {
                dispatch(SHOW_TASK_FAILURE(e));
                if (e.response.status === 401) {
                    dispatch(logout());
                }
            }
        }
    };
};

export const SHOW_TASK_REQUEST = () => {
    return {
        type: types.SHOW_TASK_REQUEST,
    };
};

export const SHOW_TASK_SUCCESS = (task) => {
    return {
        type: types.SHOW_TASK_SUCCESS,
        task: task.data,
        query: task.query,
        count: task.count,
        totalCount: task.totalCount,
        page: task.page,
        pageSize: task.pageSize,
    };
};

export const SHOW_TASK_FAILURE = (error) => {
    return {
        type: types.SHOW_TASK_FAILURE,
        error: error,
    };
};

const updateRoleModalTypes = {
    OPEN_UPDATE_ROLE_MODAL: "OPEN_UPDATE_ROLE_MODAL",
    CLOSE_UPDATE_ROLE_MODAL: "CLOSE_UPDATE_ROLE_MODAL",

    UPDATE_TITLE: "UPDATE_TITLE",
    UPDATE_SELECTED: "UPDATE_SELECTED",
    UPDATE_MODAL_ROLE: "UPDATE_MODAL_ROLE",
};

export default updateRoleModalTypes;

import React from "react";
import { Table, Row } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { showActionLog } from "../../store/actionLog/action";

const ActionLogTable = () => {
    const dispatch = useDispatch();
    const { actionLog, query, totalCount, page, pageSize } = useSelector((state) => state.actionLog);
    const { token } = useSelector((state) => state.auth);
    const { Column } = Table;

    let tableOption = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
    };

    const convertDateFormat = (text, option) => {
        let date = new Date(text)
            .toLocaleString("en-GB", option)
            .replace(/(\d+)\/(\d+)\/(\d+)/, "$3/$2/$1")
            .replace(",", "\n")
        return date;
    };

    const handleTableChange = (pagination, filters, sorter) => {
        dispatch(showActionLog(
            query,
            pagination.current,
            pagination.pageSize,
            token,
        ));
    };

    return (
        <>
            <Row style={{ paddingTop: "1rem", paddingBottom: "1rem" }}>Total Count: {totalCount}</Row>
            <Table
                dataSource={actionLog}
                size="small"
                pagination={{
                    current: page,
                    pageSize: pageSize,
                    total: totalCount,
                }}
                onChange={handleTableChange}
            >
                <Column
                    title="Username"
                    dataIndex="username"
                    key="username"
                    align="center"
                />

                <Column
                    title="IP Address"
                    dataIndex="ipAddress"
                    key="ipAddress"
                    align="center"
                />

                <Column
                    title="Action Category"
                    dataIndex="actionCategory"
                    key="actionCategory"
                    align="center"
                />

                <Column
                    title="Date Time"
                    dataIndex="createdAt"
                    key="createdAt"
                    align="center"
                    render={value => convertDateFormat(value, tableOption)}
                />
            </Table>
        </>
    );
};

export default ActionLogTable;

import React, { useEffect } from "react";
import { Breadcrumb } from "antd";
import { UserDeleteOutlined, ExclamationCircleTwoTone } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import SearchForm from "../../components/SearchForm/SearchForm";
import RecordTable from "../../components/RecordTable/RecordTable";
import { showResult } from "../../store/result/action";
import { removeFpmsPlayer } from "../../store/removeFpmsPlayer/action";
import { UPDATE_SELECTED_FIELDS } from "../../store/searchForm/action";

const Result = () => {
    const dispatch = useDispatch();
    const { token, permissions } = useSelector((state) => state.auth);
    const {
        result,
        query,
        totalCount,
        page,
        pageSize,
        searchFormSettings,
        tableSettings,
    } = useSelector((state) => state.result);

    useEffect(() => {
        dispatch(UPDATE_SELECTED_FIELDS({ page: [] }));
        dispatch(showResult(
            {},
            1,
            20,
            token
        ));
    }, [dispatch, token]);

    const getAuthorizedAction = () => {
        let actions = {
            externalActions: [],
        };

        if (permissions?.result?.removeFpmsPlayer) {
            actions.externalActions.push({
                function: deleteRecord,
                hidden: record => !record?.username || record?.taskObjId?.isGlife || !record?.taskObjId?.removeFpmsPlayer,
                icon: <UserDeleteOutlined twoToneColor="#eb2f96" />,
                enablePopconfirm: {
                    title: (record) => {
                        return (record.status === 3 || record.status === 4)
                            ? 'The bot is still running. Are you sure to remove this player?'
                            : 'Are you sure to remove this player?';
                    },
                    okText: "Remove",
                    cancelText: "Cancel",
                    icon: <ExclamationCircleTwoTone twoToneColor="red" />
                },
                title: "Remove FPMS player",
                color: "red",
            });
        }

        return actions;
    };

    const deleteRecord = (record) => {
        const payload = {
            platformId: record?.taskObjId?.platformId,
            playerId: record?.username,
            phoneNumber: Object.values(record?.taskObjId?.steps || {})
                .map(step => step.phoneNumber)
                .find(Boolean),
        };
        dispatch(removeFpmsPlayer(
            payload,
            record.taskObjId._id,
            token,
        ));
    };

    if (permissions && !permissions.result?.getResult) {
        return <Redirect to="/" />;
    }
    return (
        <>
            <Breadcrumb style={{ margin: "16px 0" }}>
                <Breadcrumb.Item>Show Result</Breadcrumb.Item>
            </Breadcrumb>
            <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
                {searchFormSettings ? (
                    <SearchForm
                        searchBy={searchFormSettings}
                        showRecord={showResult}
                    />
                ) : (
                    <></>
                )}

                {tableSettings ? (
                    <RecordTable
                        state={{
                            records: result,
                            query,
                            totalCount,
                            page,
                            pageSize,
                        }}
                        tableSettings={tableSettings}
                        showRecord={showResult}
                        actions={getAuthorizedAction()}
                    />
                ) : (
                    <></>
                )}
            </div>
        </>
    );
};

export default Result;

import axios from "axios";
import config from "../config";

const requests = {
    get: async (token, path, query = {}) => {
        let axiosConfig = {};
        axiosConfig = { params: query };
        if (token) {
            axiosConfig.headers = { Authorization: `Bearer ${token}` };
        }
        const res = await axios.get(`${config.api_uri}${path}`, axiosConfig);
        return res;
    },

    post: async (token, path, body = {}) => {
        let axiosConfig = {};
        if (token) {
            axiosConfig.headers = { Authorization: `Bearer ${token}` };
        }
        const res = await axios.post(
            `${config.api_uri}${path}`,
            body,
            axiosConfig
        );
        return res;
    },

    put: async (token, path, body = {}) => {
        let axiosConfig = {};
        if (token) {
            axiosConfig.headers = { Authorization: `Bearer ${token}` };
        }
        const res = await axios.put(
            `${config.api_uri}${path}`,
            body,
            axiosConfig
        );
        return res;
    },
};

export default requests;

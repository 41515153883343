import { message } from "antd";
import requests from "../../services/requests";
import { showTask } from "../task/action";
import types from "./types";

export const addTask = ({ values, page, pageSize, token, query, closeModal }) => {
    return async (dispatch) => {
        try {
            dispatch(ADD_TASK_REQUEST());
            await requests.post(token, "/task/add", values);
            dispatch(ADD_TASK_SUCCESS());
            dispatch(showTask(
                query,
                page,
                pageSize,
                token
            ));
            closeModal();
            message.success("Successfully added a new task", 1);
        } catch (e) {
            dispatch(ADD_TASK_FAILURE(e));
            message.error(e?.response?.data?.message ?? "Add task failure.", 1);
        }
    };
};

export const ADD_TASK_REQUEST = () => {
    return {
        type: types.ADD_TASK_REQUEST,
    };
};

export const ADD_TASK_SUCCESS = () => {
    return {
        type: types.ADD_TASK_SUCCESS,
    };
};

export const ADD_TASK_FAILURE = (error) => {
    return {
        type: types.ADD_TASK_FAILURE,
        error: error,
    };
};

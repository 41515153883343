import updateRoleModalTypes from "./types";

const initialState = {
    visible: false,
    role: null,
    title: null,
    selected: null,
};

function updateRoleModalReducer(state = initialState, action) {
    switch (action.type) {
        case updateRoleModalTypes.OPEN_UPDATE_ROLE_MODAL:
            return { ...state, visible: true };
        case updateRoleModalTypes.CLOSE_UPDATE_ROLE_MODAL:
            return { ...state, visible: false, role: null, selected: null };
        case updateRoleModalTypes.UPDATE_TITLE:
            return { ...state, title: action.title };
        case updateRoleModalTypes.UPDATE_SELECTED:
            return { ...state, selected: action.permissions };
        case updateRoleModalTypes.UPDATE_MODAL_ROLE:
            return { ...state, role: action.role, title: action.title, selected: action.selected };
        default:
            return state;
    }
}

export default updateRoleModalReducer;

/*----------------------------------------------------------------------------------------------------
Need to provide tableSettings when using.
Example:
    <RecordTable
        showRecord = { function }
        state = { object }
        pageSizeOptions = {[ number ]}
        tableSettings = {[ object ]}
        actions = { object }
        fixedSelectedColumn = {[ string ]}
        showSelectColumn = { boolean }
    />
showRecord = function to show data
state = current state
pageSizeOptions = the size of the table
fixedSelectedColumn = fixed column, cannot be selected to not display
showSelectColumn = can set whether to allow selection of selected column
state example:
    {
        records: records,
        query: query,
        totalCount: totalCount,
        page: page,
        pageSize: pageSize,
    }

records:
    - data type: [ Object, Object ]
    - description: All records in the table.

query:
    - data type: Object
    - description: The query that will be sent to the backend when the pagination changed.

totalCount:
    - data type: Number
    - description: Total number of records.

page:
    - data type: Number
    - description: Current page.

pageSize:
    - data type: Number
    - description: The number of records that a page will display.

tableSettings = Settings used to automatically generate table columns.
tableSettings example:
    [
        {
            name: "Name",
            dataKey: "name",
        },
        {
            name: "Name 2",
            dataKey: "name2",
            excludeDefaultSelectedColumn: true,
        },
        {
            name: "Name 3",
            dataKey: "name3",
            ellipsis: true,
        },
        {
            name: "Name 4",
            dataKey: "name4",
            ellipsis: {
                lineLimit: 1,
            },
        },
        {
            name: "Name 5",
            dataKey: "name5",
            showFullText: true,
        },
        {
            name: "Name 6",
            dataKey: "name6",
            hidden: () => {
                *** Can write some logic to determine whether to hide the element. ***
            },
        },
        {
            name: "Type",
            dataKey: "type",
            convertToText: {
                value1: "Text1",
                value2: "Text2",
                default: "Unknown Type",
            },
        },
        {
            name: "Date Time 1",
            dataKey: "dateTime1",
            convertToDate: true,
        },
        {
            name: "Date Time 2",
            dataKey: "dateTime2",
            convertToDate: {
                option: {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                    hour: "2-digit",
                    minute: "2-digit",
                    second: "2-digit",
                }
            },
        },
        {
            name: "Status",
            dataKey: "status",
            convertToTag: {
                1: {
                    color: "green",
                    text: "Active",
                },
                default: {
                    color: "red",
                    text: "Deactive",
                },
            },
        },
        {
            name: "Name 6",
            dataKey: "name6",
            isArray: true,
        },
        {
            name: "Links Record 1",
            dataKey: "linksRecord1",
            isArray: {
                dataKey: "_id",
            }
        },
        {
            name: "Links Record 2",
            dataKey: ["_id", "linksRecord2"],
        },
        {
            name: "Object",
            dataKey: "object",
            isObject: true,
        },
        {
            name: "Pop-up Modal",
            dataKey: "popupModal",
            popupModal: {
                showRecord: (query, page, pageSize, token) => {},
                defaultQuery: { refObjId: "64392c52c0c0b68fd6d59674" },
                searchFormSettings: [
                    { name: "Name", type: "text", dataKey: "name" },
                    { name: "Name 2", type: "text", dataKey: "name2" },
                ],
                searchFormFixedQuery: { environment: "devtest" },
                tableSettings: [
                    {  name: "Name", dataKey: "name" },
                    {  name: "Name 2", dataKey: "name2" },
                ],
                state: {
                    records: [],
                    query: {},
                    totalCount: 0,
                    page: 1,
                    pageSIze: 10,
                },
            },
        },
        {
            name: "Pop-up Modal 2",
            dataKey: "popupModal2",
            popupModal: {
                showRecord: (query, page, pageSize, token) => {},
                defaultQuery: (value, record) => {
                    *** Can write some logic using the parameter `value` and `record` and return different values. ***
                },
                searchFormSettings: (value, record) => {
                    *** Can write some logic using the parameter `value` and `record` and return different values. ***
                },
                searchFormFixedQuery: (value, record) => {
                    *** Can write some logic using the parameter `value` and `record` and return different values. ***
                },
                tableSettings: (value, record) => {
                    *** Can write some logic using the parameter `value` and `record` and return different values. ***
                },
                state: (value, record) => {
                    *** Can write some logic using the parameter `value` and `record` and return different values. ***
                },
            },
        },
        {
            name: "Expand Table",
            dataKey: "expandTable",
            expandTable: {
                tableSettings: [
                    {  name: "Name", dataKey: "name" },
                    {  name: "Name 2", dataKey: "name2" },
                ],
            }
        },
        {
            name: "Expand Table 2",
            dataKey: "expandTable2",
            expandTable: {
                tableSettings: (value, record) => {
                    *** Can write some logic using the parameter `value` and `record` and return different values. ***
                },
            }
        },
    ],

name:
    - data type: String
    - description: Title of the table column

dataKey:
    - data type: String | [ String ]
    - description: Display field of the data record, support nest path by string array.

hidden:
    - data type: Function
    - description: Whether to hide the value or element of the column.
                   The function needs to return a Boolean data type.

excludeDefaultSelectedColumn (optional):
    - data type: Boolean
    - description: Whether to automatically select `Select Columns`.
    - default: false

ellipsis (optional):
    - data type: Boolean | { lineLimit: Number }
    - description: Allow setting whether an ellipsis is required
                   If object is provided, allowed to set the maximum number of lines, default is 2
    - default: false

showFullText (optional):
    - data type: Boolean
    - description: Allow setting whether to display full text
    - default: false

convertToText (optional):
    - data type: Object
    - description: The object key is the value that needs to be changed, and the key value is the text displayed in the table

convertToDate (optional):
    - data type: Boolean | { option: Object }
    - description: If the data type is Boolean, default date option will be used.
                   If the data type is Object, the date option can be changed.
    - default: {
        option: {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
        },
    }

convertToTag (optional):
    - data type: { [value]: { color: String, text: String } }
    - description: Displaying as a tag in table.
                   The object key is the value that will use the setting, and the key value is the setting of the tag.
                   The data type of the key value is object, color and text can be provided.
                   color = Tag color.
                   text = The text displayed in the tag.

isArray (optional):
    - data type: Boolean | { dataKey: Object }
    - description: Provide Boolean if value is an array.
                   If value is array of object, must provide an object and dataKey.
                   dataKey = The key used to display in the table.

isImage (optional):
    - data type: Boolean
    - description: A button will be added to the table.
                   When clicked, a pop-up window will appear displaying an image.

isVideo (optional):
    - data type: Boolean
    - description: A button will be added to the table.
                   When clicked, a pop-up window will appear to play the video.

isObject (optional):
    - data type: Boolean
    - description: A button will be added to the table.
                   When clicked, a pop-up window will appear displaying the value of the object.

popupModal (optional):
    - data type: {
                showRecord: showRecord in SearchForm.jsx and RecordTable.jsx,
                defaultQuery: Function (value, record) | Object,
                searchFormSettings: Function (value, record) | searchBy in SearchForm.jsx,
                searchFormFixedQuery: Function (value, record) | fixedQuery in SearchForm.jsx,
                tableSettings: Function (value, record) | tableSettings in RecordTable.jsx,
                state: Function (value, record) | state in RecordTable.jsx,
            }
    - description: A button will be added to the table.
                   When clicked, a pop-up window will appear which can contain a SearchForm and a Table.
    - optional key: - showRecord: The function that triggers an API call to retrieve data when the page is switched.
                    - defaultQuery: The default query when triggering an API call to retrieve data. If showRecord is not set, there is no need to set this.
                    - searchFormSettings: The settings for the search form. Can refer to the comments in SearchForm.jsx for more information.
                    - searchFormFixedQuery: Can refer to the comments in SearchForm.jsx for more information.
                    - tableSettings: The settings for the table. Can refer to the above comments for more information.
                    - state: Can refer to the above comments for more information.

expandTable (optional):
    - data type: {
                tableSettings: Function (value, record) | tableSettings in RecordTable.jsx,
            }
    - description: A button will be added to the table.
                   When clicked, a table can be expanded.
    - optional key: - tableSettings: The settings for the table. Can refer to the above comments for more information.

actions example:
    {
        add: { function: Function, icon: Component },
        externalActions: [
            {
                function: Function,
                icon: Component | Function,
                title: String | Function,
                color: String | Function,
                returnValue: Any,
            },
            {
                function: Function,
                hidden: Boolean,
                icon: Component | Function,
                title: String | Function,
                color: String | Function,
                returnValue: Any,
            },
            {
                function: Function,
                enablePopconfirm: {
                    title: String | Array | Function,
                    okButtonProps: Object | Function,
                    okText: String | Function,
                    cancelText: String | Function,
                    icon: Component | Function,
                },
                icon: Component | Function,
                title: String | Function,
                color: String | Function,
                returnValue: Any,
            },
            {
                function: Function,
                icon: [
                    { dataKey: String, value: Any, icon: Component },
                    { dataKey: String, value: Any, icon: Component },
                ],
                title: [
                    { dataKey: String, value: Any, text: String },
                    { dataKey: String, value: Any, text: String },
                ],
                color: [
                    { dataKey: String, value: Any, text: String },
                    { dataKey: String, value: Any, text: String },
                ],
                returnValue: [
                    { dataKey: String, value: Any, returnValue: Any },
                    { dataKey: String, value: Any, returnValue: Any },
                ],
            },
        ],
    }

 add (optional):
    - data type: { function: Function, icon: Component }
    - description: - function: function use to open add modal.
                   - icon: The button or icon used to open the add modal.

 externalActions (optional):
    - data type: [ Object, Object ]
    - required key: - function: callback function.
    - optional key: - hidden: can set whether to hide. datatype can be boolean or function.
                              If it is boolean, can set true or false.
                              If it is a function, there will be a parameter pass in, this parameter is the selected record data.
                    - enablePopconfirm: allow setting confirm pop-up
                    - icon: icon settings. datatype can be array of object or component or function.
                            If it is an array of object, different icon can be set according to the key and value of the record.
                            If it is a function, there will be a parameter pass in, this parameter is the selected record data.
                    - title: title settings. datatype can be array of object or string or function.
                             If it is an array of object, different title can be set according to the key and value of the record.
                             If it is a function, there will be a parameter pass in, this parameter is the selected record data.
                    - color: color settings. datatype can be array of object or string or function.
                             If it is an array of object, different color can be set according to the key and value of the record.
                             If it is a function, there will be a parameter pass in, this parameter is the selected record data.
                    - returnValue: returnValue settings. Can be any datatype. If it is an array of object, different return value can be set according to the key and value of the record.
----------------------------------------------------------------------------------------------------*/

import React, { useState, cloneElement } from "react";
import ReactJson from 'react-json-view';
import {
    Popconfirm,
    Space,
    Table,
    Tag,
    Tooltip,
    Button,
    Row,
    Col,
    Select,
    Modal,
} from "antd";
import { Option } from "antd/lib/mentions";
import {
    DownloadOutlined,
    EyeOutlined,
    FileTextOutlined,
    BarsOutlined,
} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { saveAs } from 'file-saver';
import config from "../../config";
import SearchForm from "../SearchForm/SearchForm";
import DisplayVisualMediaModal from "../DisplayVisualMediaModal/DisplayVisualMediaModal";
import "./RecordTable.css";

let expandedTableList = {};
let existsUniqueId = [];

const RecordTable = ({
    showRecord,
    state = {},
    pageSizeOptions,
    tableSettings = [],
    actions,
    fixedSelectedColumn = [],
    showSelectColumn = true,
}) => {
    const dispatch = useDispatch();
    const { records = [], query, totalCount, page, pageSize } = state;
    const { token } = useSelector(state => state.auth);
    const { Column } = Table;
    const selectColumn = [];
    const defaultSelectedColumn = [];
    tableSettings.forEach(field => {
        if (field.name && !fixedSelectedColumn.includes(field.name)) {
            selectColumn.push(field.name);
            if (!field.excludeDefaultSelectedColumn) defaultSelectedColumn.push(field.name);
        }
    });
    const [ selectedField, setSelectedField ] = useState(defaultSelectedColumn ?? selectColumn);
    const [ selectedExpandedTable, setSelectedExpandedTable ] = useState({});

    const tableOption = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
    };

    let pagination = {
        defaultCurrent: 1,
        defaultPageSize: 10,
        total: totalCount ?? records.length,
        showSizeChanger: true,
        pageSizeOptions: pageSizeOptions ?? [10, 20, 50, 100],
    };
    if (page) pagination.current = page;
    if (pageSize) pagination.pageSize = pageSize;

    const rowSelection = {
        type: "checkbox",
        onSelect: (record) => {
            actions.select.function(record);
        },

        selectedRowKeys: actions?.select?.selectedRowKeys ?? [],
        selectedRows: actions?.select?.selectedRows ?? [],
        columnTitle: <></>,
    };

    const convertDateFormat = (text, option) => {
        let date = new Date(text)
            .toLocaleString("en-GB", option)
            .replace(/(\d+)\/(\d+)\/(\d+)/, "$1/$2/$3")
            .replace(",", "\n");
        return date;
    };

    const onChange = (values) => {
        setSelectedField(values);
    };

    const handleTableChange = (pagination) => {
        if (showRecord) {
            dispatch(showRecord(
                query ? query : {},
                pagination.current,
                pagination.pageSize,
                token,
            ));
        }
        setSelectedExpandedTable({});
    };

    const arrayToText = (values, objectKey) => {
        let text = '';
        for (let i = 0; i < values.length; i++) {
            text += i === 0
                ? objectKey
                    ? values[i][objectKey]
                    : values[i]
                : `, ${objectKey ? values[i][objectKey] : values[i]}`;
        }
        return text;
    };

    const textToComponent = (field, text) => {
        return (
            <div
                className={field.showFullText ? "fulltext" : field.ellipsis ? "ellipsistext" : "noellipsistext"}
                style={{
                    "--lineLimit": (field.ellipsis && field.ellipsis.constructor === Object && field.ellipsis.lineLimit) || 2,
                }}
            >
                {text?.constructor === Object ? JSON.stringify(text) : String(text)}
            </div>
        );
    };

    const generateUniqueId = () => {
        let dateTime = new Date().toISOString().split(".")[0];
        dateTime = dateTime.replace(/[^0-9]/g, "");
        const uniqueId = `${dateTime}${randomStringGenerator(6)}`;
        if (existsUniqueId.includes(uniqueId))
            return generateUniqueId();
        existsUniqueId.push(existsUniqueId);
        return uniqueId;
    };

    const randomStringGenerator = (len) => {
        let text = "";
        let charset = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        for (let i = 0; i < len; i++)
            text += charset.charAt(Math.floor(Math.random() * charset.length));

        return text;
    };

    const DisplayJsonModal = ({ field, json }) => {
        const [ visible, setVisible ] = useState(false);

        const closeModal = () => {
            setVisible(false);
        };

        return (
            <>
                <Tooltip
                    title='View'
                >
                    <EyeOutlined
                        onClick={() => {
                            setVisible(true);
                        }}
                        style={{
                            color: "#1890ff",
                        }}
                    />
                </Tooltip>

                <Modal
                    title={field?.name ?? "Display JSON Modal"}
                    visible={visible}
                    onOk={closeModal}
                    onCancel={closeModal}
                    footer={null}
                    destroyOnClose
                >
                    <ReactJson
                        src={json}
                        name={false}
                        displayDataTypes={false}
                    />
                </Modal>
            </>
        );
    };

    const PopupModal = ({ field, value, record }) => {
        const settings = field?.popupModal ?? {};
        const popupSearchFormSettings = settings?.searchFormSettings?.constructor === Function
            ? settings.searchFormSettings(value, record)
            : settings.searchFormSettings;
        const popupTableSettings = settings?.tableSettings?.constructor === Function
            ? settings.tableSettings(value, record)
            : settings.tableSettings;
        const [ visible, setVisible ] = useState(false);
        useSelector(state => state); // use to re-render page.

        const closeModal = () => {
            setVisible(false);
        };

        const onClick = () => {
            if (settings?.showRecord) {
                const defaultQuery = settings?.defaultQuery?.constructor === Function
                    ? settings.defaultQuery(value, record)
                    : settings.defaultQuery ?? {};
                dispatch(settings.showRecord(
                    defaultQuery,
                    1,
                    10,
                    token
                ));
            }

            setVisible(true);
        };

        return (
            <>
                <Tooltip
                    title={`Pop-up ${field.name} Modal`}
                >
                    <FileTextOutlined
                        onClick={onClick}
                        style={{
                            color: "#1890ff",
                        }}
                    />
                </Tooltip>

                <Modal
                    title={field.name ?? "Records"}
                    visible={visible}
                    onOk={closeModal}
                    onCancel={closeModal}
                    footer={null}
                    destroyOnClose
                    width={"90%"}
                >
                    {popupSearchFormSettings ? (
                        <SearchForm
                            searchBy = { popupSearchFormSettings }
                            showRecord = { settings?.showRecord }
                            fixedQuery = {
                                settings?.searchFormFixedQuery?.constructor === Function
                                    ? settings.searchFormFixedQuery(value, record)
                                    : settings.searchFormFixedQuery
                            }
                            isModal = { true }
                        />
                    ) : (
                        <></>
                    )}

                    {popupTableSettings ? (
                        <RecordTable
                            state = { settings?.state(value, record) }
                            tableSettings = { popupTableSettings }
                            showRecord = { settings?.showRecord }
                        />
                    ) : (
                        <></>
                    )}
                </Modal>
            </>
        );
    };

    const ExpandTable = ({ field, value = [], record }) => {
        const settings = field?.expandTable ?? {};
        const expandTableSettings = settings?.tableSettings?.constructor === Function
            ? settings.tableSettings(value, record)
            : settings.tableSettings;

        value.forEach(x => {
            if (!x._id)
                x._id = generateUniqueId();
        });

        expandedTableList = {
            ...expandedTableList,
            [`${record._id}${field.dataKey}`]: <div
                style={{
                    margin: '0 2%',
                    padding: "0 1%",
                    backgroundColor: 'white',
                }}
            >
                {expandTableSettings ? (
                    <RecordTable
                        state = {{
                            records: value,
                        }}
                        tableSettings = { expandTableSettings }
                    />
                ) : (
                    <></>
                )}
            </div>,
        };

        return (
            <Tooltip
                title={`Expand ${field.name} Table`}
            >
                <BarsOutlined
                    style={{
                        color: "#1890ff",
                    }}
                />
            </Tooltip>
        );
    };

    const renderColumn = (field) => {
        if (field.name && (selectedField.includes(field.name) || fixedSelectedColumn.includes(field.name))) {
            let columnProps = {
                title: field.name,
                dataIndex: field.dataKey,
                key: field.dataKey,
                align: "center",
                // ellipsis: field.ellipsis,
            };

            if (field.expandTable) {
                columnProps.onCell = record => ({
                    onClick: () => {
                        if (!Object.keys(selectedExpandedTable).includes(record._id) || selectedExpandedTable[record._id] !== field.dataKey) {
                            return setSelectedExpandedTable({
                                ...selectedExpandedTable,
                                [record._id]: field.dataKey,
                            });
                        }

                        let newSelectedExpandedTable = { ...selectedExpandedTable };
                        delete newSelectedExpandedTable[record._id];
                        setSelectedExpandedTable(newSelectedExpandedTable);
                    }
                });
            }

            return (
                <Column
                    { ...columnProps }
                    render={
                        field.hidden?.constructor === Function && field.hidden()
                        ? null
                        : field.convertToText
                        ? value => {
                            let convertedText = null;

                            Object.keys(field.convertToText).forEach(key => {
                                if (String(value) === key) return convertedText = field.convertToText[key];
                            })

                            if (!convertedText && field.convertToText.default) convertedText = field.convertToText.default;
                            else if (!convertedText) convertedText = '-';

                            return textToComponent(field, convertedText);
                        }
                        : field.convertToDate
                        ? value => value ? convertDateFormat(value, field.convertToDate.option ?? tableOption) : '-'
                        : field.convertToTag
                        ? value => {
                            let component = null;

                            Object.keys(field.convertToTag).forEach(key => {
                                if (String(value) === key) {
                                    component = (
                                        <Tag
                                            color={field.convertToTag[key].color ?? "warning"}
                                            key={field.dataKey}
                                        >
                                            {field.convertToTag[key].text ?? String(value)}
                                        </Tag>
                                    );
                                }
                            })

                            if (!component && field.convertToTag.default) {
                                component = (
                                    <Tag
                                        color={field.convertToTag.default.color ?? "warning"}
                                        key={field.dataKey}
                                    >
                                        {field.convertToTag.default.text ?? String(value)}
                                    </Tag>
                                );
                            }
                            else if (!component) {
                                component = (
                                    <Tag
                                        color="warning"
                                        key={field.dataKey}
                                    >
                                        {String(value)}
                                    </Tag>
                                );
                            }

                            return component;
                        }
                        : field.isArray
                        ? value => value?.length ? textToComponent(field, arrayToText(value, field.isArray.dataKey)) : "-"
                        : field.isImage
                        ? value => {
                            if (!value || !value.length) return "-";

                            let components = [
                                <DisplayVisualMediaModal
                                    title={field.name}
                                    images={value}
                                />,
                            ];

                            if (field.allowDownload) {
                                components.push(
                                    <Tooltip title="Download">
                                        <DownloadOutlined
                                            style={{ color: "#1890ff" }}
                                            onClick={() => {
                                                if (Array.isArray(value)) {
                                                    value.forEach(image => saveAs(`${config.api_uri}${image}`, image.slice(image.lastIndexOf('/') + 1)));
                                                }

                                                if (typeof value === 'string') saveAs(`${config.api_uri}${value}`, value.slice(value.lastIndexOf('/') + 1));
                                            }}
                                        />
                                    </Tooltip>
                                );
                            }

                            return <Space size="small">{components}</Space>;
                        }
                        : field.isVideo
                        ? value => {
                            if (!value || !value.length) return "-";

                            let components = [
                                <DisplayVisualMediaModal
                                    title={field.name}
                                    videos={value}
                                />,
                            ];

                            if (field.allowDownload) {
                                components.push(
                                    <Tooltip title="Download">
                                        <DownloadOutlined
                                            style={{ color: "#1890ff" }}
                                            onClick={() => {
                                                if (Array.isArray(value)) {
                                                    value.forEach(video => saveAs(`${config.api_uri}${video}`, video.slice(video.lastIndexOf('/') + 1)));
                                                }

                                                if (typeof value === 'string') saveAs(`${config.api_uri}${value}`, value.slice(value.lastIndexOf('/') + 1));
                                            }}
                                        />
                                    </Tooltip>
                                );
                            }

                            return <Space size="small">{components}</Space>;
                        }
                        : field.isObject
                        ? value => <DisplayJsonModal
                            field={field}
                            json={value}
                        />
                        : field.popupModal
                        ? (value, record) => <PopupModal
                            field={field}
                            value={value}
                            record={record}
                        />
                        : field.expandTable
                        ? (value, record) => <ExpandTable
                            field={field}   
                            value={value}
                            record={record}
                        />
                        : value => value || value === 0 ? textToComponent(field, value) : "-"
                    }
                />
            );
        }
    };

    const getExternalActionSetting = (record, setting) => {
        if (!setting?.constructor) return;

        if (React.isValidElement(setting) || setting.constructor === Boolean) return setting;

        if (setting.constructor === Function) return setting(record);

        if (setting.constructor === Array) {
            for (const x of setting) {
                if (!React.isValidElement(x) && record[x.dataKey] === x.value) return x.component || x.text || x.returnValue;
            }
        }

        return setting;
    };

    const cloneElementAndModify = ({ component, props }) => {
        if (!component) {
            return <></>;
        }
        const element = cloneElement(
            component,
            props,
        );
        return element;
    };

    const renderAction = () => {
        return (
            <Column
                title="Action"
                key="Action"
                align="center"
                width={`${actions.externalActions.length > 1 ? actions.externalActions.length * 30 : 60}px`}
                fixed="right"
                render={(text, record, index) => (
                    <Space size="small">
                        {actions.externalActions.map(externalAction => {
                            const Icon = cloneElementAndModify({
                                component: getExternalActionSetting(record, externalAction.icon) || <></>,
                                props: {
                                    style: { color: getExternalActionSetting(record, externalAction.color) },
                                    onClick: !externalAction.enablePopconfirm ? () => externalAction.function(record, getExternalActionSetting(record, externalAction.returnValue)) : null,
                                },
                            });
                            return !getExternalActionSetting(record, externalAction.hidden) ? (
                                externalAction.enablePopconfirm ? (
                                    <Popconfirm
                                        title={getExternalActionSetting(record, externalAction.enablePopconfirm.title)}
                                        onConfirm={() => externalAction.function(record, getExternalActionSetting(record, externalAction.returnValue))}
                                        okButtonProps={getExternalActionSetting(record, externalAction.enablePopconfirm.okButtonProps)}
                                        okText={getExternalActionSetting(record, externalAction.enablePopconfirm.okText)}
                                        cancelText={getExternalActionSetting(record, externalAction.enablePopconfirm.cancelText)}
                                        icon={getExternalActionSetting(record, externalAction.enablePopconfirm.icon)}
                                    >
                                        <Tooltip title={getExternalActionSetting(record, externalAction.title)}>
                                            {Icon}
                                        </Tooltip>
                                    </Popconfirm>
                                ) : (
                                    <Tooltip title={getExternalActionSetting(record, externalAction.title)}>
                                        {Icon}
                                    </Tooltip>
                                )
                            ) : (
                                <></>
                            );
                        })}
                    </Space>
                )}
            />
        );
    };

    const expandedRowRender = (record) => {
        /*
        When import or declare a component in React and use it twice, and the component uses
        useState to declare its state, may encounter an issue where one component
        uses the initial value of useState from the other component. This is often caused
        by the module import mechanism in JavaScript.

        To solve this issue, need to ensure that each component has its own independent state.
        The solution used in this part of the code is to create a new Class component to achieve this.

        Note: The other solution is to use `useEffect` to set the initial value of `useState`.
              When `tableSettings` changes, use set function to update the value of `useState`.
        */
        const SetIndependentState = ({ component }) => {
            return component;
        };
        return Object.keys(selectedExpandedTable).includes(record._id) ? (
            <SetIndependentState component={expandedTableList[`${record._id}${selectedExpandedTable[record._id]}`]} />
        ) : (
            <></>
        );
    };

    return (
        <>
            {actions?.add ? (
                <Row gutter={[0, 24]} style={{ paddingTop: "1rem" }}>
                    {cloneElementAndModify({
                        component: actions.add.icon ?? <Button type="primary" size="small" > + Add </Button>,
                        props: {
                            style: { borderRadius: 5 },
                            onClick: actions.add.function,
                        },
                    })}
                </Row>
            ) : (
                <></>
            )}
            <Row style={{ paddingTop: "1rem", paddingBottom: "1rem" }}>
                <Col span={12} align="left">
                    Total Count: {totalCount ?? records.length}
                </Col>
                {showSelectColumn ? (
                    <Col span={12} align="right">
                        <Space>
                            <div>Select Columns</div>
                            <Select
                                onChange={onChange}
                                style={{ width: "10rem" }}
                                className="select-columns"
                                mode="multiple"
                                maxTagCount={0}
                                showArrow
                                value={selectedField}
                                allowClear
                            >
                                {selectColumn.map(field => (
                                    <Option value={field}>
                                        {field}
                                    </Option>
                                ))}
                            </Select>
                        </Space>
                    </Col>
                ) : (
                    <></>
                )}
            </Row>
            <Table
                id="recordtable"
                dataSource={records}
                size="small"
                scroll={{ x: (selectedField?.length ?? 0) * 200 }}
                pagination={pagination}
                onChange={handleTableChange}
                rowKey={"_id"}
                rowSelection={actions?.select ? rowSelection : null}
                expandable={{
                    expandIconColumnIndex: -1,
                    expandedRowKeys: Object.keys(selectedExpandedTable),
                    expandedRowRender,
                }}
                style={{
                    whiteSpace: "pre-wrap", // avoid multiple spaces will automatically replaced by 1 space
                }}
            >
                {tableSettings.map(field => {
                    return renderColumn(field);
                })}

                {actions?.externalActions?.length ? (
                    renderAction()
                ) : (
                    <></>
                )}
            </Table>
        </>
    );
};

export default RecordTable;

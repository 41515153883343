import requests from "../../services/requests";
import { logout } from "../auth/action";
import types from "./types";

export const showActionLog = (query, page, pageSize, token) => {
    return async (dispatch) => {
        let response;
        const options = { query: query, page: page, pageSize: pageSize };
        try {
            dispatch(SHOW_ACTION_LOG_REQUEST());
            response = await requests.get(token, "/actionLog/all", options);
            dispatch(SHOW_ACTION_LOG_SUCCESS(response.data));
        } catch (e) {
            if (e.response) {
                dispatch(SHOW_ACTION_LOG_FAILURE(e));
                if (e.response.status === 401) {
                    dispatch(logout());
                }
            }
        }
    };
};

export const SHOW_ACTION_LOG_REQUEST = () => {
    return {
        type: types.SHOW_ACTION_LOG_REQUEST,
    };
};

export const SHOW_ACTION_LOG_SUCCESS = (actionLog) => {
    return {
        type: types.SHOW_ACTION_LOG_SUCCESS,
        actionLog: actionLog.data,
        query: actionLog.query,
        count: actionLog.count,
        totalCount: actionLog.totalCount,
        page: actionLog.page,
        pageSize: actionLog.pageSize,
    };
};

export const SHOW_ACTION_LOG_FAILURE = (error) => {
    return {
        type: types.SHOW_ACTION_LOG_FAILURE,
        error: error,
    };
};

import resultLogTypes from "./types";

const initialState = {
    isLoading: false,
    resultLog: [],
    query: {},
    count: 1,
    totalCount: 1,
    page: 1,
    pageSize: 10,
    searchFormSettings: [
        {
            name: "Credits",
            type: "number",
            dataKey: "credits",
        },
        {
            name: "Lock In Deposit",
            type: "number",
            dataKey: "lockInDeposit",
        },
        {
            name: "Points",
            type: "number",
            dataKey: "points",
        },
        {
            name: "Step",
            type: "text",
            dataKey: "step",
        },
        {
            name: "Remark",
            type: "text",
            dataKey: "remark",
        },
    ],
    tableSettings: [
        {
            name: "Credits",
            dataKey: "credits",
        },
        {
            name: "Lock In Deposit",
            dataKey: "lockInDeposit",
            excludeDefaultSelectedColumn: true,
        },
        {
            name: "Points",
            dataKey: "points",
        },
        {
            name: "Step",
            dataKey: "step",
        },
        {
            name: "Remark",
            dataKey: "remark",
        },
        {
            name: "Action List",
            dataKey: "actionList",
            expandTable: {
                tableSettings: [
                    {
                        name: "Action",
                        dataKey: "action",
                    }, {
                        name: 'Consumption Details',
                        dataKey: 'consumptionDetails',
                        expandTable: {
                            tableSettings: [
                                {
                                    name: "Previous Amount",
                                    dataKey: "previousAmount"
                                }, {
                                    name: "Win Loss Amount",
                                    dataKey: "winLossAmount"
                                }, {
                                    name: "Current Amount",
                                    dataKey: "currentAmount"
                                }
                            ]
                        }
                    },
                    {
                        name: "Proposal ID",
                        dataKey:"proposalId"
                    },
                    {
                        name: "Previous Credits",
                        dataKey: "previousCredits",
                    },
                    {
                        name: "Sign Up Bonus Amount Claimed",
                        dataKey: "signUpBonusAmountClaimed",
                    },
                    {
                        name: "Actual Deposit Amount",
                        dataKey: "actualDepositAmount",
                    },
                    {
                        name: "Actual Withdraw Amount",
                        dataKey: "actualWithdrawAmount",
                    },
                    {
                        name: "Provider Name",
                        dataKey: "providerName",
                    },
                    {
                        name: "Previous Amount",
                        dataKey: "previousAmount",
                    },
                    {
                        name: "Current Amount",
                        dataKey: "currentAmount",
                    },
                    {
                        name: "Current Credits",
                        dataKey: "currentCredits",
                    },
                    {
                        name: "Locked In Deposit",
                        dataKey: "lockedInDeposit",
                        excludeDefaultSelectedColumn: true,
                    },
                    {
                        name: "Old Password",
                        dataKey: "oldPassword",
                        excludeDefaultSelectedColumn: true,
                    },
                    {
                        name: "New Password",
                        dataKey: "newPassword",
                        excludeDefaultSelectedColumn: true,
                    },
                    {
                        name: "Failure Case",
                        dataKey: "isFailureCase",
                        convertToText: {
                            true: "Yes",
                            false: "No",
                            default: "Unknown",
                        },
                    },
                    {
                        name: "Bot Interrupted",
                        dataKey: "isBotInterrupted",
                        convertToText: {
                            true: "Yes",
                            false: "No",
                            default: "Unknown",
                        },
                    },
                ],
            }
        },
        {
            name: "Button Action Results",
            dataKey: "buttonActionResults",
            expandTable: {
                tableSettings: [
                    {
                        name: "Button",
                        dataKey: "button",
                    },
                    {
                        name: "Success",
                        dataKey: "success",
                        convertToTag: {
                            true: {
                                color: "green",
                                text: "True",
                            },
                            false: {
                                color: "red",
                                text: "False",
                            },
                            default: {
                                color: "warning",
                                text: "Unknown",
                            },
                        },
                    },
                    {
                        name: "Remark",
                        dataKey: "remark",
                    },
                ],
            }
        },
        {
            name: "Created Time",
            dataKey: "createdAt",
            convertToDate: true,
        },
    ],
};

function resultLogReducer(state = initialState, action) {
    switch (action.type) {
        case resultLogTypes.SHOW_RESULT_LOG_REQUEST:
            return { ...state, isLoading: true };
        case resultLogTypes.SHOW_RESULT_LOG_SUCCESS:
            return {
                ...state,
                isLoading: false,
                resultLog: action.resultLog,
                query: action.query,
                count: action.count,
                totalCount: action.totalCount,
                page: action.page,
                pageSize: action.pageSize,
            };
        case resultLogTypes.SHOW_RESULT_LOG_FAILURE:
            return { ...state, isLoading: false, error: action.error };
        default:
            return state;
    }
}

export default resultLogReducer;

import { Popconfirm, Space, Table, Tooltip, Row, Tag, message } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    ExclamationCircleTwoTone,
    DeleteTwoTone,
    EditTwoTone,
    PoweroffOutlined,
    CheckOutlined,
    CloseOutlined,
    UndoOutlined,
} from "@ant-design/icons";
import DisplayJsonModal from "../DisplayJsonModal/DisplayJsonModal";
import { showTask } from "../../store/task/action";
import { updateTaskStatus } from "../../store/updateTaskStatus/action";
import { deleteTask } from "../../store/deleteTask/action";
import AddOrUpdateRecordModal from "../../components/AddOrUpdateRecordModal/AddOrUpdateRecordModal";
import { updateTask } from "../../store/updateTask/action";

const TaskTable = () => {
    const dispatch = useDispatch();
    const { task, query, count, totalCount, page, pageSize, formSettings } = useSelector((state) => state.task);
    const { token, permissions } = useSelector((state) => state.auth);
    const { Column } = Table;

    const tableDateOption = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
    };

    const convertDateFormat = (text, option) => {
        let date = new Date(text)
            .toLocaleString("en-GB", option)
            .replace(/(\d+)\/(\d+)\/(\d+)/, "$1/$2/$3")
            .replace(",", "\n");
        return date;
    };

    const handleTableChange = (pagination, filters, sorter) => {
        dispatch(showTask(
            query,
            pagination.current,
            pagination.pageSize,
            token,
        ));
    };

    const getActionWidth = () => {
        let numOfAction = 0;
        if (permissions?.task?.editTask) numOfAction += 3;
        if (permissions?.task?.deleteTask) numOfAction += 1;
        return `${numOfAction > 1 ? numOfAction * 30 : 60}px`;
    };

    const handleUpdateStatus = (record, status, options = {}) => {
        dispatch(updateTaskStatus(
            record,
            status,
            options,
            page,
            pageSize,
            token,
            query
        ));
    };

    const handleDelete = (text, record, index) => {
        if (count !== 1 || page === 1) {
            dispatch(deleteTask(record, page, pageSize, token, query));
        } else {
            dispatch(deleteTask(record, page-1, pageSize, token, query));
        }
    };

    return (
        <>
            <Row style={{ paddingBottom: "1rem" }}>Total Count: {totalCount}</Row>
            <Table
                dataSource={task}
                size="small"
                scroll={{ x: 4 * 200 }}
                pagination={{
                    current: page,
                    pageSize: pageSize,
                    total: totalCount,
                    showSizeChanger: true,
                }}
                onChange={handleTableChange}
                style={{
                    whiteSpace: "pre-wrap", // avoid multiple spaces will automatically replaced by 1 space
                }}
            >
                <Column
                    title="Task Name"
                    dataIndex="taskName"
                    key="taskName"
                    align="center"
                />

                <Column
                    title="Client Domain"
                    dataIndex="clientDomain"
                    key="clientDomain"
                    align="center"
                />

                <Column
                    title="Platform ID"
                    dataIndex="platformId"
                    key="platformId"
                    align="center"
                />

                <Column
                    title="Device ID"
                    dataIndex="deviceId"
                    key="deviceId"
                    align="center"
                    render={value => value ?? "-"}
                />

                <Column
                    title="Is Glife"
                    dataIndex="isGlife"
                    key="isGlife"
                    align="center"
                    render={value => {
                        return value ? (
                            <CheckOutlined style={{ color: 'green' }} />
                        ) : (
                            <CloseOutlined style={{ color: 'red' }} />
                        );
                    }}
                />
                
                <Column
                    title="Glife Type"
                    dataIndex="glifeType"
                    key="glifeType"
                    align="center"
                    render={value => value ?? "-"}
                />

                <Column
                    title="Run Time"
                    dataIndex="runTime"
                    key="runTime"
                    align="center"
                    render={value => value ?? "-"}
                />

                <Column
                    title="Next Execution Time"
                    dataIndex="nextExecutionTime"
                    key="nextExecutionTime"
                    align="center"
                    render={value => value ? convertDateFormat(value, tableDateOption) : '-'}
                />

                <Column
                    title="Steps"
                    key="steps"
                    align="center"
                    render={record => (
                        record?.steps && Object.keys(record.steps).map(key => {
                            return record.steps[key].hasChecked ? (
                                <DisplayJsonModal
                                    title = {key}
                                    json = {record.steps[key]}
                                    icon = {<Tag color="blue">{key}</Tag>}
                                />
                            ) : (
                                <></>
                            );
                        })
                    )}
                />

                <Column
                    title="Remove FPMS Player"
                    dataIndex="removeFpmsPlayer"
                    key="removeFpmsPlayer"
                    align="center"
                    render={record => (
                        record
                        ? <Tag color="green">
                            Yes
                        </Tag>
                        : <Tag color="red">
                            No
                        </Tag>
                    )}
                />

                <Column
                    title="Status"
                    dataIndex="status"
                    key="status"
                    align="center"
                    render={(status) => (
                        status === 0
                        ? <Tag color="red" key={status}>
                            {"Deactivate"}
                        </Tag>
                        : status === 1
                        ? <Tag color="green" key={status}>
                            {"Active"}
                        </Tag>
                        : status === 3
                        ? <Tag color="blue" key={status}>
                            {"Running"}
                        </Tag>
                        : <Tag color="warning" key={status}>
                            {"Unknown"}
                        </Tag>
                    )}
                />

                {permissions?.task?.editTask || permissions?.task?.deleteTask ? (
                    <Column
                        title="Action"
                        key="Action"
                        align="center"
                        width={getActionWidth()}
                        fixed="right"
                        render={(text, record, index) => (
                            <Space size="small">
                                {permissions?.task?.editTask ? (
                                    <AddOrUpdateRecordModal
                                        title = "Update Task"
                                        okText = "Submit"
                                        beforeOnClick={() => {
                                            if (record.status === 3) {
                                                message.warning(`Edit is not allowed because ${record.taskName} is running!`);
                                                return false;
                                            }
                                            return true;
                                        }}
                                        component={<Tooltip title="Edit"><EditTwoTone/></Tooltip>}
                                        state={{
                                            query,
                                            page,
                                            pageSize,
                                        }}
                                        updateRecord={{
                                            record,
                                            callback: updateTask,
                                        }}
                                        formSettings={formSettings}
                                    />
                                ) : (
                                    <></>
                                )}

                                {permissions?.task?.editTask ? (
                                    <Tooltip title={record.status === 0 ? "Active": "Deactive"}>
                                        <PoweroffOutlined
                                            style={{
                                                color: record.status === 0 ? "green" : "#eb2f96",
                                            }}
                                            onClick={() => {
                                                if (record.status === 3) {
                                                    return message.warning(`Cannot change the status, because ${record.taskName} is running!`);
                                                }
                                                handleUpdateStatus(record, record.status === 0 ? 1 : 0);
                                            }}
                                        />
                                    </Tooltip>
                                ) : (
                                    <></>
                                )}

                                {permissions?.task?.editTask ? (
                                    <Popconfirm
                                        title="Are you sure to reset the running status?"
                                        onConfirm={() => {
                                            handleUpdateStatus(record, 1, { updateIfRunning: true });
                                        }}
                                        okText="Yes"
                                        cancelText="No"
                                        icon={
                                            <ExclamationCircleTwoTone twoToneColor="red" />
                                        }
                                    >
                                        <Tooltip title="Reset running status">
                                            <UndoOutlined
                                                style={{
                                                    color: "#eb2f96",
                                                }}
                                            />
                                        </Tooltip>
                                    </Popconfirm>
                                ) : (
                                    <></>
                                )}

                                {permissions?.roles?.deleteRole ? (
                                    <Popconfirm
                                        title="Are you sure to delete this task?"
                                        onConfirm={() => {
                                            if (record.status === 3) {
                                                return message.warning(`Delete is not allowed because ${record.taskName} is running!`);
                                            }
                                            handleDelete(text, record, index);
                                        }}
                                        okText="Yes"
                                        cancelText="No"
                                        icon={
                                            <ExclamationCircleTwoTone twoToneColor="red" />
                                        }
                                    >
                                        <Tooltip title="Delete">
                                            <DeleteTwoTone twoToneColor="#eb2f96" />
                                        </Tooltip>
                                    </Popconfirm>
                                ) : (
                                    <></>
                                )}
                            </Space>
                        )}
                    />
                ) : (
                    <></>
                )}
            </Table>
        </>
    );
};

export default TaskTable;

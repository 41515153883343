import requests from "../../services/requests";
import { logout } from "../auth/action";
import types from "./types";

export const showRoles = (page, pageSize, token) => {
    return async (dispatch) => {
        let response;
        const options = { page: page, pageSize: pageSize };
        try {
            dispatch(SHOW_ROLE_REQUEST());
            response = await requests.get(token, "/role/all", options);
            dispatch(SHOW_ROLE_SUCCESS(response.data));
        } catch (e) {
            if (e.response) {
                dispatch(SHOW_ROLE_FAILURE(e));
                if (e.response.status === 401) {
                    dispatch(logout());
                }
            }
        }
    };
};

export const SHOW_ROLE_REQUEST = () => {
    return {
        type: types.SHOW_ROLE_REQUEST,
    };
};

export const SHOW_ROLE_SUCCESS = (roles) => {
    return {
        type: types.SHOW_ROLE_SUCCESS,
        role: roles.data,
        count: roles.count,
        totalCount: roles.totalCount,
        page: roles.page,
        pageSize: roles.pageSize,
    };
};

export const SHOW_ROLE_FAILURE = (error) => {
    return {
        type: types.SHOW_ROLE_FAILURE,
        error: error,
    };
};

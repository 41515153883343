import settingsTypes from "./types";

const initialState = {
    isLoading: false,
    settings: {},
};

function settingsReducer(state = initialState, action) {
    switch (action.type) {
        case settingsTypes.SHOW_SETTINGS_REQUEST:
            return { ...state, isLoading: true };
        case settingsTypes.SHOW_SETTINGS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                settings: action.settings,
            };
        case settingsTypes.SHOW_SETTINGS_FAILURE:
            return { ...state, isLoading: false, error: action.error };
        default:
            return state;
    }
}

export default settingsReducer;

import React from "react";
import { Redirect } from "react-router-dom";
import { Form, Input, Button, Card } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../store/auth/action";

const SignIn = () => {
    const dispatch = useDispatch();
    const token = useSelector((state) => state.auth.token);

    const onFinish = (values) => {
        dispatch(login(values));
    };

    if (token) {
        return <Redirect to="/" />;
    }

    return (
        <>
            <div
                className="site-layout-background"
                style={{
                    paddingTop: "5rem",
                    minHeight: 360,
                    position: "relative",
                }}
            >
                <Card
                    title="Sign In"
                    style={{
                        borderRadius: 10,
                        display: "block",
                        paddingT: "2rem",
                        marginLeft: "auto",
                        marginRight: "auto",
                        width: "40%",
                    }}
                >
                    <Form
                        name="Sign In"
                        initialValues={{ remember: true }}
                        onFinish={onFinish}
                    >
                        <Form.Item
                            label="Username"
                            name="username"
                            rules={[
                                {
                                    required: true,
                                    message: "Please input your username!",
                                },
                            ]}
                        >
                            <Input
                                style={{
                                    borderRadius: 5,
                                    width: "100%",
                                }}
                            />
                        </Form.Item>

                        <Form.Item
                            label="Password"
                            name="password"
                            rules={[
                                {
                                    required: true,
                                    message: "Please input your password!",
                                },
                            ]}
                        >
                            <Input.Password
                                style={{
                                    borderRadius: 5,
                                    width: "100%",
                                }}
                            />
                        </Form.Item>

                        <Form.Item>
                            <Button
                                type="primary"
                                htmlType="submit"
                                style={{
                                    borderRadius: 5,
                                    width: "100%",
                                }}>
                                Submit
                            </Button>
                        </Form.Item>
                    </Form>
                </Card>
            </div>
        </>
    );
};

export default SignIn;

import requests from "../../services/requests";
import { logout } from "../auth/action";
import types from "./types";

export const showResult = (query, page, pageSize, token) => {
    return async (dispatch) => {
        let response;
        const options = { query: query, page: page, pageSize: pageSize };
        try {
            dispatch(SHOW_RESULT_REQUEST());
            response = await requests.get(token, "/result/all", options);
            dispatch(SHOW_RESULT_SUCCESS(response.data));
        } catch (e) {
            if (e.response) {
                dispatch(SHOW_RESULT_FAILURE(e));
                if (e.response.status === 401) {
                    dispatch(logout());
                };
            };
        };
    };
};

export const SHOW_RESULT_REQUEST = () => {
    return {
        type: types.SHOW_RESULT_REQUEST,
    };
};

export const SHOW_RESULT_SUCCESS = (result) => {
    return {
        type: types.SHOW_RESULT_SUCCESS,
        result: result.data,
        query: result.query,
        count: result.count,
        totalCount: result.totalCount,
        page: result.page,
        pageSize: result.pageSize,
    };
};

export const SHOW_RESULT_FAILURE = (error) => {
    return {
        type: types.SHOW_RESULT_FAILURE,
        error: error,
    };
};

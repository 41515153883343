import React, { useEffect } from "react";
import { Breadcrumb, Button, Row } from "antd";
import { useDispatch, useSelector } from "react-redux";
import RoleTable from "../../components/RolesTable/RolesTable";
import { showRoles } from "../../store/roles/action";
import { Redirect } from "react-router-dom";
import { SET_ADD_PERMISSIONS } from "../../store/addRole/action";
import AddRoleModal from "../../components/AddRoleModal/AddRoleModal";
import UpdateRoleModal from "../../components/UpdateRoleModal/UpdateRoleModal";
import { OPEN_ADD_ROLE_MODAL } from "../../store/addRoleModal/action";

const Roles = () => {
    const dispatch = useDispatch();
    const { token, permissions, permList } = useSelector((state) => state.auth);

    useEffect(() => {
        dispatch(showRoles(1, 10, token));
    });

    if (permissions && !permissions.roles?.getRole) {
        return <Redirect to="/" />;
    }

    const openModal = () => {
        dispatch(SET_ADD_PERMISSIONS(permList));
        dispatch(OPEN_ADD_ROLE_MODAL());
    };

    return (
        <>
            <Breadcrumb style={{ margin: "16px 0" }}>
                <Breadcrumb.Item>Show Roles</Breadcrumb.Item>
            </Breadcrumb>
            <div
                className="site-layout-background"
                style={{ padding: 24, minHeight: 360 }}
            >
                <Row gutter={[0, 24]} style={{ paddingBottom: "1rem" }}>
                    {permissions?.roles?.addRole ? (
                        <Button type="primary" onClick={openModal}>
                            Add New Role
                        </Button>
                    ) : (
                        <></>
                    )}
                </Row>
                <RoleTable />
                <AddRoleModal />
                <UpdateRoleModal />
            </div>
        </>
    );
};

export default Roles;

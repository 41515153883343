import { Checkbox, Form, Input, List, Modal } from "antd";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateRole } from "../../store/updateRole/action";
import {
    CLOSE_UPDATE_ROLE_MODAL,
    UPDATE_SELECTED,
    UPDATE_TITLE,
} from "../../store/updateRoleModal/action";

const UpdateRoleModal = () => {
    const dispatch = useDispatch();
    const { visible, role, title, selected } = useSelector(
        (state) => state.updateRoleModal
    );
    const { token, permList } = useSelector((state) => state.auth);
    const { page, pageSize } = useSelector((state) => state.roles);
    const permKeyList = {};
    const [keyState, setKeyState] = useState(permKeyList);

    for (const key in permList) {
        let checked = true;
        for (const subKey in permList[key]) {
            if (role && selected && selected[key]) {
                if (!selected[key][subKey]) {
                    checked = false;
                }
            } else {
                checked = false;
            }
        }
        keyState[key] = checked;
    }

    const onKeyChange = (key, status) => {
        let tempKeyState = JSON.parse(JSON.stringify(keyState));
        tempKeyState[key] = status;
        setKeyState(tempKeyState);

        let state = JSON.parse(JSON.stringify(selected));
        Object.keys(state[key]).forEach((subKey) => {
            state[key][subKey] = status;
        });
        dispatch(UPDATE_SELECTED(state));
    };

    const onSubKeyChange = (key, subKey, status) => {
        let state = JSON.parse(JSON.stringify(selected));
        state[key][subKey] = status;
        dispatch(UPDATE_SELECTED(state));
    };

    const [form] = Form.useForm();
    if (title) {
        form.setFieldsValue({ title: title });
    }

    const onFinish = (values) => {
        Object.keys(values).forEach((s) => {
            if (!values[s]) {
                delete values[s];
            }
        });
        dispatch(
            updateRole(
                role.title,
                values.title,
                selected,
                page,
                pageSize,
                token
            )
        );
    };

    const closeModal = () => {
        form.resetFields();
        dispatch(CLOSE_UPDATE_ROLE_MODAL());
    };

    return (
        <Modal
            title="Update Role"
            visible={visible}
            onOk={() => {
                form.validateFields().then((values) => {
                    onFinish(values, form);
                });
            }}
            onCancel={closeModal}
            okText="Submit"
            cancelText="Cancel"
        >
            <Form
                name="Update Role"
                form={form}
            >
                <Form.Item
                    label="Role title"
                    name="title"
                    rules={[
                        {
                            required: true,
                            message: "Please input the role's title!",
                            whitespace: true,
                        },
                    ]}
                >
                    <Input
                        onBlur={(e) => {
                            dispatch(UPDATE_TITLE(e.target.value));
                        }}
                    />
                </Form.Item>
                <List
                    dataSource={permList ? Object.keys(permList) : []}
                    size="small"
                    renderItem={(key) => (
                        <List.Item key={key}>
                            <List.Item.Meta
                                title={
                                    <Checkbox
                                        checked={keyState[key]}
                                        onChange={() =>
                                            onKeyChange(key, !keyState[key])
                                        }
                                    >
                                        {key[0].toUpperCase() + key.slice(1)}
                                    </Checkbox>
                                }
                                description={Object.keys(permList[key]).map(
                                    (subKey) => (
                                        <Checkbox
                                            checked={
                                                selected[key] &&
                                                selected[key][subKey]
                                                    ? selected[key][subKey]
                                                    : false
                                            }
                                            onChange={() => {
                                                onSubKeyChange(
                                                    key,
                                                    subKey,
                                                    !selected[key][subKey]
                                                );
                                            }}
                                        >
                                            {subKey}
                                        </Checkbox>
                                    )
                                )}
                            />
                        </List.Item>
                    )}
                />
            </Form>
        </Modal>
    );
};

export default UpdateRoleModal;

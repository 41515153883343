import { Checkbox, Form, Input, List, Modal } from "antd";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addRole, SET_ADD_PERMISSIONS } from "../../store/addRole/action";
import { CLOSE_ADD_ROLE_MODAL } from "../../store/addRoleModal/action";

const AddRoleModal = () => {
    const dispatch = useDispatch();
    const { selectedPermissions } = useSelector((state) => state.addRole);
    const { visible } = useSelector((state) => state.addRoleModal);
    const { token, permList } = useSelector((state) => state.auth);
    const { pageSize } = useSelector((state) => state.roles);

    const permKeyList = {};
    if (permList) {
        Object.keys(permList).forEach((key) => (permKeyList[key] = false));
    }
    const [keyState, setKeyState] = useState({ permKeyList });
    const onKeyChange = (key, status) => {
        let tempKeyState = JSON.parse(JSON.stringify(keyState));
        tempKeyState[key] = status;
        setKeyState(tempKeyState);

        let state = JSON.parse(JSON.stringify(selectedPermissions));
        Object.keys(state[key]).forEach((subKey) => {
            state[key][subKey] = status;
        });
        dispatch(SET_ADD_PERMISSIONS(state));
    };

    const onSubKeyChange = (key, subKey, status) => {
        let state = JSON.parse(JSON.stringify(selectedPermissions));
        state[key][subKey] = status;
        dispatch(SET_ADD_PERMISSIONS(state));
    };

    const [form] = Form.useForm();

    const onFinish = (values) => {
        Object.keys(values).forEach((s) => {
            if (!values[s]) {
                delete values[s];
            }
        });
        values.permissions = selectedPermissions;
        dispatch(addRole(values, 1, pageSize, form, token));
        setKeyState({});
    };

    const closeModal = () => {
        form.resetFields();
        setKeyState({});
        dispatch(CLOSE_ADD_ROLE_MODAL());
    };

    return (
        <Modal
            title="Add Role"
            visible={visible}
            onOk={() => {
                form.validateFields().then((values) => {
                    onFinish(values);
                });
            }}
            onCancel={closeModal}
            okText="Submit"
            cancelText="Cancel"
        >
            <Form name="Add Role" form={form}>
                <Form.Item
                    label="Role title"
                    name="title"
                    rules={[
                        {
                            required: true,
                            message: "Please input the role's title!",
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <List
                    dataSource={permList ? Object.keys(permList) : []}
                    size="small"
                    renderItem={(key) => (
                        <List.Item key={key}>
                            <List.Item.Meta
                                title={
                                    <Checkbox
                                        checked={keyState[key]}
                                        onChange={() =>
                                            onKeyChange(key, !keyState[key])
                                        }
                                    >
                                        {key[0].toUpperCase() + key.slice(1)}
                                    </Checkbox>
                                }
                                description={
                                    selectedPermissions ? (
                                        Object.keys(permList[key]).map(
                                            (subKey) => (
                                                <Checkbox
                                                    checked={
                                                        selectedPermissions[key][subKey]
                                                    }
                                                    onChange={() => {
                                                        onSubKeyChange(
                                                            key,
                                                            subKey,
                                                            !selectedPermissions[key][subKey]
                                                        );
                                                    }}
                                                >
                                                    {subKey}
                                                </Checkbox>
                                            )
                                        )
                                    ) : (
                                        <></>
                                    )
                                }
                            ></List.Item.Meta>
                        </List.Item>
                    )}
                ></List>
            </Form>
        </Modal>
    );
};

export default AddRoleModal;

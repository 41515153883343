import deleteRoleTypes from "./types";

const initialState = {
    isLoading: false,
    error: null,
};

function deleteRoleReducer(state = initialState, action) {
    switch (action.type) {
        case deleteRoleTypes.DELETE_ROLE_REQUEST:
            return { ...state, isLoading: true };
        case deleteRoleTypes.DELETE_ROLE_SUCCESS:
            return { ...state, isLoading: false };
        case deleteRoleTypes.DELETE_ROLE_FAILURE:
            return { ...state, isLoading: false, error: action.error };
        default:
            return state;
    }
}

export default deleteRoleReducer;

import updateTaskStatusTypes from "./types";

const initialState = {
    isLoading: false,
    error: null,
};

function updateTaskStatusReducer(state = initialState, action) {
    switch (action.type) {
        case updateTaskStatusTypes.UPDATE_TASK_STATUS_REQUEST:
            return { ...state, isLoading: true };
        case updateTaskStatusTypes.UPDATE_TASK_STATUS_SUCCESS:
            return { ...state, isLoading: false };
        case updateTaskStatusTypes.UPDATE_TASK_STATUS_FAILURE:
            return { ...state, isLoading: false, error: action.error };
        default:
            return state;
    }
}

export default updateTaskStatusReducer;

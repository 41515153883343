import React, { useEffect } from "react";
import {
    Breadcrumb,
    Button,
    Row,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
// import SearchForm from "../../components/SearchForm/SearchForm";
import TaskTable from "../../components/TaskTable/TaskTable";
import AddOrUpdateRecordModal from "../../components/AddOrUpdateRecordModal/AddOrUpdateRecordModal";
import { UPDATE_SELECTED_FIELDS } from "../../store/searchForm/action";
import { showTask } from "../../store/task/action";
import { addTask } from "../../store/addTask/action";

const BotSetting = () => {
    const dispatch = useDispatch();
    const { token, permissions } = useSelector((state) => state.auth);
    const { formSettings, query, page, pageSize } = useSelector((state) => state.task);

    useEffect(() => {
        dispatch(UPDATE_SELECTED_FIELDS({page: []}));
        dispatch(showTask(
            {},
            1,
            20,
            token
        ));
    }, [token, dispatch]);

    if (permissions && !permissions.task?.getTask) {
        return <Redirect to="/" />;
    }

    return (
        <>
            <Breadcrumb style={{ margin: "16px 0" }}>
                <Breadcrumb.Item>Show Tasks</Breadcrumb.Item>
            </Breadcrumb>
            <div
                className="site-layout-background"
                style={{ padding: 24, minHeight: 360 }}
            >
                {/* <SearchForm
                    searchBy = {[
                        {
                            name: "Bot Name",
                            type: "text",
                            dataKey: "botName"
                        },
                    ]}
                    showRecord = {showBotSetting}
                /> */}
                <Row gutter={[0, 24]} style={{ paddingTop: "1rem", paddingBottom: "1rem" }}>
                    <AddOrUpdateRecordModal
                        title = "Add New Task"
                        okText = "Submit"
                        component = {
                            <Button type="primary" style={{ borderRadius: 5 }}>Add</Button>
                        }
                        formSettings={formSettings}
                        addRecord={{
                            callback: addTask,
                        }}
                        state={{
                            query,
                            page,
                            pageSize,
                        }}
                    />
                </Row>
                <TaskTable />
            </div>
        </>
    );
};

export default BotSetting;

import taskTypes from "./types";

const initialState = {
    isLoading: false,
    task: [],
    query: {},
    count: 1,
    totalCount: 1,
    page: 1,
    pageSize: 10,
    formSettings: [
        {
            label: "Task Name",
            dataKey: "taskName",
            type: "text",
            updateDisabled: true,
            rules: {
                required: true,
            },
        },
        {
            label: "Client Domain",
            dataKey: "clientDomain",
            type: "text",
            rules: {
                required: true,
            },
        },
        {
            label: "Platform ID",
            dataKey: "platformId",
            type: "text",
            updateDisabled: true,
            rules: {
                required: true,
            },
        },
        {
            label: "Device ID",
            dataKey: "deviceId",
            type: "text",
            rules: {
                required: true,
            },
        },
        {
            label: "Is GLife",
            dataKey: "isGlife",
            type: "checkbox",
            initialValue: false,
        },
        {
            label: "GLife Type",
            dataKey: "glifeType",
            type: "selectOption",
            options: [
                { label: "TABLE GAME", value: "TABLE GAME" },
                { label: "COLOR GAME", value: "COLOR GAME" },
                { label: "OSM", value: "OSM" },
            ],
            condition: {
                field: "isGlife",
                value: true,
            },
            rules: {
                required: true,
            },
        },
        {
            label: "Run Time",
            dataKey: "runTime",
            type: "time",
        },
        {
            label: "Sign Up",
            dataKey: ["steps", "signup", "hasChecked"],
            type: "checkbox",
            initialValue: false,
        },
        {
            label: "Phone Number",
            dataKey: ["steps", "signup", "phoneNumber"],
            type: "text",
            condition: {
                field: ["steps", "signup", "hasChecked"],
                value: true,
            },
            rules: {
                required: true,
                number: true,
            },
        },
        {
            label: "KYC",
            dataKey: ["steps", "kyc", "hasChecked"],
            type: "checkbox",
            initialValue: false,
        },
        {
            label: "Date of Birth",
            dataKey: ["steps", "kyc", "dob"],
            type: "date",
            condition: {
                field: ["steps", "kyc", "hasChecked"],
                value: true,
            },
            rules: {
                required: true,
            },
        },
        {
            label: "First Name",
            dataKey: ["steps", "kyc", "firstName"],
            type: "text",
            condition: {
                field: ["steps", "kyc", "hasChecked"],
                value: true,
            },
            rules: {
                required: true,
            },
        },
        {
            label: "Middle Name",
            dataKey: ["steps", "kyc", "middleName"],
            type: "text",
            condition: {
                field: ["steps", "kyc", "hasChecked"],
                value: true,
            },
        },
        {
            label: "Last Name",
            dataKey: ["steps", "kyc", "lastName"],
            type: "text",
            condition: {
                field: ["steps", "kyc", "hasChecked"],
                value: true,
            },
            rules: {
                required: true,
            },
        },
        {
            label: "Email",
            dataKey: ["steps", "kyc", "email"],
            type: "text",
            condition: {
                field: ["steps", "kyc", "hasChecked"],
                value: true,
            },
            rules: {
                required: true,
                email: true,
            },
        },
        {
            label: "Source of Funds",
            dataKey: ["steps", "kyc", "sof"],
            type: "selectOption",
            options: [
                { label: "Business", value: "Business" },
                { label: "Employment", value: "Employment" },
                { label: "Inheritance/trust", value: "Inheritance/trust" },
                { label: "Investments", value: "Investments" },
                { label: "Pensions/annuites", value: "Pensions/annuites" },
                { label: "Rentals/lease", value: "Rentals/lease" },
                { label: "Others", value: "Others" },
            ],
            condition: {
                field: ["steps", "kyc", "hasChecked"],
                value: true,
            },
            rules: {
                required: true,
            },
        },
        {
            label: "Other Source of Funds",
            dataKey: ["steps", "kyc", "otherSof"],
            type: "text",
            condition: {
                field: ["steps", "kyc", "sof"],
                value: "Others",
            },
            rules: {
                required: true,
            },
        },
        {
            label: "Nationality",
            dataKey: ["steps", "kyc", "nationality"],
            type: "selectOption",
            options: [
                { label: "Philippines", value: "Philippines" }
            ],
            condition: {
                field: ["steps", "kyc", "hasChecked"],
                value: true,
            },
            rules: {
                required: true,
            },
        },
        {
            label: "Address",
            dataKey: ["steps", "kyc", "address"],
            type: "text",
            condition: {
                field: ["steps", "kyc", "hasChecked"],
                value: true,
            },
            rules: {
                required: true,
            },
        },
        {
            label: "Province",
            dataKey: ["steps", "kyc", "province"],
            type: "text",
            condition: {
                field: ["steps", "kyc", "hasChecked"],
                value: true,
            },
            rules: {
                required: true,
            },
        },
        {
            label: "City",
            dataKey: ["steps", "kyc", "city"],
            type: "text",
            condition: {
                field: ["steps", "kyc", "hasChecked"],
                value: true,
            },
            rules: {
                required: true,
            },
        },
        {
            label: "Type",
            dataKey: ["steps", "kyc", "type"],
            type: "selectOption",
            options: [
                { label: "ID", value: "ID" }
            ],
            condition: {
                field: ["steps", "kyc", "hasChecked"],
                value: true,
            },
            rules: {
                required: true,
            },
        },
        {
            label: "KYC Photo",
            dataKey: ["steps", "kyc", "kycPhoto"],
            type: "image",
            condition: {
                field: ["steps", "kyc", "hasChecked"],
                value: true,
            },
            multiple: false,
            rules: {
                required: true,
            },
        },
        {
            label: "Apply Sign Up Bonus",
            dataKey: ["steps", "applySignUpBonus", "hasChecked"],
            type: "checkbox",
            initialValue: false,
        },
        {
            label: "Phone Number",
            dataKey: ["steps", "applySignUpBonus", "phoneNumber"],
            type: "text",
            condition: {
                field: ["steps", "applySignUpBonus", "hasChecked"],
                value: true,
            },
            rules: {
                required: true,
                number: true,
            },
        },
        {
            label: "Login",
            dataKey: ["steps", "login", "hasChecked"],
            type: "checkbox",
            initialValue: false,
        },
        {
            label: "Phone Number",
            dataKey: ["steps", "login", "phoneNumber"],
            type: "text",
            condition: {
                field: ["steps", "login", "hasChecked"],
                value: true,
            },
            rules: {
                required: true,
                number: true,
            },
        },
        {
            label: "Password",
            dataKey: ["steps", "login", "password"],
            type: "text",
            condition: {
                field: ["steps", "login", "hasChecked"],
                value: true,
            },
        },
        {
            label: "Set Password",
            dataKey: ["steps", "setPassword", "hasChecked"],
            type: "checkbox",
            initialValue: false,
        },
        {
            label: "Phone Number",
            dataKey: ["steps", "setPassword", "phoneNumber"],
            type: "text",
            condition: {
                field: ["steps", "setPassword", "hasChecked"],
                value: true,
            },
            rules: {
                required: true,
                number: true,
            },
        },
        {
            label: "New Password",
            dataKey: ["steps", "setPassword", "newPassword"],
            type: "text",
            condition: {
                field: ["steps", "setPassword", "hasChecked"],
                value: true,
            },
            rules: {
                required: true,
            },
        },
        {
            label: "Change Password",
            dataKey: ["steps", "changePassword", "hasChecked"],
            type: "checkbox",
            initialValue: false,
        },
        {
            label: "Phone Number",
            dataKey: ["steps", "changePassword", "phoneNumber"],
            type: "text",
            condition: {
                field: ["steps", "changePassword", "hasChecked"],
                value: true,
            },
            rules: {
                required: true,
                number: true,
            },
        },
        {
            label: "Link GCash E-Wallet",
            dataKey: ["steps", "linkEWallet", "hasChecked"],
            type: "checkbox",
            initialValue: false,
        },
        {
            label: "Phone Number",
            dataKey: ["steps", "linkEWallet", "phoneNumber"],
            type: "text",
            condition: {
                field: ["steps", "linkEWallet", "hasChecked"],
                value: true,
            },
            rules: {
                required: true,
                number: true,
            },
        },
        {
            label: "Deposit",
            dataKey: ["steps", "deposit", "hasChecked"],
            type: "checkbox",
            initialValue: false,
        },
        {
            label: "Phone Number",
            dataKey: ["steps", "deposit", "phoneNumber"],
            type: "text",
            condition: {
                field: ["steps", "deposit", "hasChecked"],
                value: true,
            },
            rules: {
                required: true,
                number: true,
            },
        },
        {
            label: "Amount",
            dataKey: ["steps", "deposit", "amount"],
            type: "number",
            min: 0,
            condition: {
                field: ["steps", "deposit", "hasChecked"],
                value: true,
            },
            rules: {
                required: true,
            },
        },
        {
            label: "Check Transaction History",
            dataKey: ["steps", "deposit", "checkTransactionHistory", "hasChecked"],
            type: "checkbox",
            condition: {
                field: ["steps", "deposit", "hasChecked"],
                value: true,
            },
            initialValue: false,
        },
        {
            label: "Cash In",
            dataKey: ["steps", "cashIn", "hasChecked"],
            type: "checkbox",
            initialValue: false,
        },
        {
            label: "Provider Name",
            dataKey: ["steps", "cashIn", "providerName"],
            type: "selectOption",
            options: [
                { label: "LIVE", value: "LIVE" },
                { label: "COLOR GAME", value: "COLOR GAME" },
            ],
            condition: {
                field: ["steps", "cashIn", "hasChecked"],
                value: true,
            },
            rules: {
                required: true,
            },
        },
        {
            label: "Consumption",
            dataKey: ["steps", "consumption", "hasChecked"],
            type: "checkbox",
            initialValue: false,
        },
        {
            label: "Phone Number",
            dataKey: ["steps", "consumption", "phoneNumber"],
            type: "text",
            condition: {
                field: ["steps", "consumption", "hasChecked"],
                value: true,
            },
            rules: {
                required: true,
                number: true,
            },
        },
        {
            label: "Number Of Bets",
            dataKey: ["steps", "consumption", "numberOfBets"],
            type: "number",
            condition: {
                field: ["steps", "consumption", "hasChecked"],
                value: true,
            },
            initialValue: 1,
            rules: {
                required: true,
            },
        },
        {
            label: "Cash Out",
            dataKey: ["steps", "cashOut", "hasChecked"],
            type: "checkbox",
            initialValue: false,
        },
        {
            label: "Provider Name",
            dataKey: ["steps", "cashOut", "providerName"],
            type: "selectOption",
            options: [
                { label: "LIVE", value: "LIVE" },
                { label: "COLOR GAME", value: "COLOR GAME" },
            ],
            condition: {
                field: ["steps", "cashOut", "hasChecked"],
                value: true,
            },
            rules: {
                required: true,
            },
        },
        {
            label: "Withdraw",
            dataKey: ["steps", "withdraw", "hasChecked"],
            type: "checkbox",
            initialValue: false,
        },
        {
            label: "Phone Number",
            dataKey: ["steps", "withdraw", "phoneNumber"],
            type: "text",
            condition: {
                field: ["steps", "withdraw", "hasChecked"],
                value: true,
            },
            rules: {
                required: true,
                number: true,
            },
        },
        {
            label: "Amount",
            dataKey: ["steps", "withdraw", "amount"],
            type: "number",
            min: 0,
            condition: {
                field: ["steps", "withdraw", "hasChecked"],
                value: true,
            },
        },
        {
            label: "Check Transaction History",
            dataKey: ["steps", "withdraw", "checkTransactionHistory", "hasChecked"],
            type: "checkbox",
            condition: {
                field: ["steps", "withdraw", "hasChecked"],
                value: true,
            },
            initialValue: false,
        },
        {
            label: "Home Page Buttons",
            dataKey: ["steps", "homePageButtons", "hasChecked"],
            type: "checkbox",
            initialValue: false,
        },
        {
            label: "Phone Number",
            dataKey: ["steps", "homePageButtons", "phoneNumber"],
            type: "text",
            condition: {
                field: ["steps", "homePageButtons", "hasChecked"],
                value: true,
            },
            rules: {
                required: true,
                number: true,
            },
        },
        {
            label: "Cashier Page Buttons",
            dataKey: ["steps", "cashierPageButtons", "hasChecked"],
            type: "checkbox",
            initialValue: false,
        },
        {
            label: "Phone Number",
            dataKey: ["steps", "cashierPageButtons", "phoneNumber"],
            type: "text",
            condition: {
                field: ["steps", "cashierPageButtons", "hasChecked"],
                value: true,
            },
            rules: {
                required: true,
                number: true,
            },
        },
        {
            label: "CS Page Buttons",
            dataKey: ["steps", "csPageButtons", "hasChecked"],
            type: "checkbox",
            initialValue: false,
        },
        {
            label: "Phone Number",
            dataKey: ["steps", "csPageButtons", "phoneNumber"],
            type: "text",
            condition: {
                field: ["steps", "csPageButtons", "hasChecked"],
                value: true,
            },
            rules: {
                required: true,
                number: true,
            },
        },
        {
            label: "Account Page Buttons",
            dataKey: ["steps", "accountPageButtons", "hasChecked"],
            type: "checkbox",
            initialValue: false,
        },
        {
            label: "Phone Number",
            dataKey: ["steps", "accountPageButtons", "phoneNumber"],
            type: "text",
            condition: {
                field: ["steps", "accountPageButtons", "hasChecked"],
                value: true,
            },
            rules: {
                required: true,
                number: true,
            },
        },
        {
            label: "Remove FPMS Player",
            dataKey: "removeFpmsPlayer",
            type: "checkbox",
            initialValue: false,
        },
    ],
};

function taskReducer(state = initialState, action) {
    switch (action.type) {
        case taskTypes.SHOW_TASK_REQUEST:
            return { ...state, isLoading: true };
        case taskTypes.SHOW_TASK_SUCCESS:
            return {
                ...state,
                isLoading: false,
                task: action.task,
                query: action.query,
                count: action.count,
                totalCount: action.totalCount,
                page: action.page,
                pageSize: action.pageSize,
            };
        case taskTypes.SHOW_TASK_FAILURE:
            return { ...state, isLoading: false, error: action.error };
        default:
            return state;
    }
}

export default taskReducer;

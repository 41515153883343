import roleTypes from "./types";

const initialState = {
    isLoading: false,
    roles: [],
    count: 1,
    totalCount: 1,
    page: 1,
    pageSize: 10
};

function roleReducer(state = initialState, action) {
    switch (action.type) {
        case roleTypes.SHOW_ROLE_REQUEST:
            return { ...state, isLoading: true };
        case roleTypes.SHOW_ROLE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                roles: action.role,
                count: action.count,
                totalCount: action.totalCount,
                page: action.page,
                pageSize: action.pageSize,
            };
        case roleTypes.SHOW_ROLE_FAILURE:
            return { ...state, isLoading: false, error: action.error };
        default:
            return state;
    }
}

export default roleReducer;

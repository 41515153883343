import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Breadcrumb } from "antd";
import UpdateNotificationSettings from "../../components/UpdateNotificationSettings/UpdateNotificationSettings";
import { showSettings } from "../../store/settings/action";
import { Redirect } from "react-router-dom";

const Settings = () => {
    const dispatch = useDispatch();
    const { token, permissions } = useSelector((state) => state.auth);

    useEffect(() => {
        dispatch(showSettings(
            token
        ));
    });

    if (permissions && !permissions.settings?.getSettings) {
        return <Redirect to="/" />;
    }

    return (
        <>
            <Breadcrumb style={{ margin: "16px 0" }}>
                <Breadcrumb.Item>Settings</Breadcrumb.Item>
            </Breadcrumb>
            <div
                className="site-layout-background"
                style={{ padding: 24, minHeight: 360 }}
            >
                <UpdateNotificationSettings />
            </div>
        </>
    );
};

export default Settings;

import { message } from "antd";
import requests from "../../services/requests";
import { logout } from "../auth/action";
import { showRoles } from "../roles/action";
import types from "./types";

export const deleteRole = (data, page, pageSize, token) => {
    return async (dispatch) => {
        try {
            dispatch(DELETE_ROLE_REQUEST());
            await requests.post(token, "/role/delete", {
                title: data,
            });
            dispatch(DELETE_ROLE_SUCCESS());
            dispatch(showRoles(page, pageSize, token));
            message.success("Successfully deleted the role", 1);
        } catch (e) {
            dispatch(DELETE_ROLE_FAILURE(e.response.data.message));
            if (e.response.status === 401) {
                message.error("Account is Unathorized");
                dispatch(logout());
            } else {
                if (e.response && e.response.data && e.response.data.message) {
                    message.error(e.response.data.message, 1);
                } else if (
                    e.response &&
                    e.response.data &&
                    e.response.data.error
                ) {
                    message.error(e.response.data.error, 1);
                } else {
                    message.error("Error!", 1);
                }
            }
        }
    };
};

export const DELETE_ROLE_REQUEST = () => {
    return {
        type: types.DELETE_ROLE_REQUEST,
    };
};

export const DELETE_ROLE_SUCCESS = () => {
    return {
        type: types.DELETE_ROLE_SUCCESS,
    };
};

export const DELETE_ROLE_FAILURE = (error) => {
    return {
        type: types.DELETE_ROLE_FAILURE,
        error: error,
    };
};

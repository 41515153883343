import { message } from "antd";
import requests from "../../services/requests";
import { showUsers } from "../users/action";
import types from "./types";

export const addUser = ({ values, page, pageSize, token, resetModal }) => {
    return async (dispatch) => {
        try {
            dispatch(ADD_USER_REQUEST());
            await requests.post(token, "/user/add", values);
            dispatch(ADD_USER_SUCCESS());
            dispatch(showUsers(page, pageSize, token));
            resetModal();
            message.success("Successfully added a new user", 1);
        } catch (e) {
            dispatch(ADD_USER_FAILURE(e));
            message.error(e.response.data.message, 1);
        }
    };
};

export const ADD_USER_REQUEST = () => {
    return {
        type: types.ADD_USER_REQUEST,
    };
};

export const ADD_USER_SUCCESS = () => {
    return {
        type: types.ADD_USER_SUCCESS,
    };
};

export const ADD_USER_FAILURE = (error) => {
    return {
        type: types.ADD_USER_FAILURE,
        error: error,
    };
};

import { List, Popconfirm, Space, Table, Tag, Tooltip } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    ExclamationCircleTwoTone,
    DeleteTwoTone,
    EditTwoTone,
} from "@ant-design/icons";
import { deleteRole } from "../../store/deleteRole/action";
import { showRoles } from "../../store/roles/action";
import {
    OPEN_UPDATE_ROLE_MODAL,
    UPDATE_MODAL_ROLE,
} from "../../store/updateRoleModal/action";

const RolesTable = () => {
    const dispatch = useDispatch();
    const { roles, count, totalCount, page, pageSize } = useSelector(
        (state) => state.roles
    );
    const { permList, permissions } = useSelector((state) => state.auth);
    const token = useSelector((state) => state.auth.token);
    const { Column } = Table;

    const handleTableChange = (pagination) => {
        dispatch(showRoles(pagination.current, pagination.pageSize, token));
    };

    const getActionWidth = () => {
        let numOfAction = 0;
        if (permissions?.roles?.editRole) numOfAction += 1;
        if (permissions?.roles?.deleteRole) numOfAction += 1;
        return `${numOfAction > 1 ? numOfAction * 30 : 60}px`;
    };

    const confirmDelete = (text, record) => {
        if (count !== 1 || page === 1) {
            dispatch(deleteRole(record.title, page, pageSize, token));
        } else {
            dispatch(deleteRole(record.title, page - 1, pageSize, token));
        }
    };

    const update = (record) => {
        dispatch(UPDATE_MODAL_ROLE(record, permList));
        dispatch(OPEN_UPDATE_ROLE_MODAL());
    };

    return (
        <Table
            dataSource={roles}
            size="small"
            scroll={{ x: 2 * 200 }}
            pagination={{
                current: page,
                pageSize: pageSize,
                total: totalCount,
                showSizeChanger: true,
            }}
            onChange={handleTableChange}
        >
            <Column
                title="Role Title"
                dataIndex="title"
                key="title"
                width="10%"
            />

            <Column
                title="Permissions"
                key="permissions"
                render={(text, record) => (
                    <List
                        dataSource={permList ? Object.keys(permList) : []}
                        size="small"
                        renderItem={(key) => (
                            <List.Item key={key}>
                                <List.Item.Meta
                                    title={key}
                                    description={Object.keys(permList[key]).map(
                                        (subKey) =>
                                            record.permissions[key] &&
                                            record.permissions[key][subKey] ? (
                                                <Tag color="green" key={subKey}>
                                                    {subKey}
                                                </Tag>
                                            ) : (
                                                <Tag color="red" key={subKey}>
                                                    {subKey}
                                                </Tag>
                                            )
                                    )}
                                />
                            </List.Item>
                        )}
                    />
                )}
            />

            {permissions?.roles?.editRole || permissions?.roles?.deleteRole ? (
                <Column
                    title="Action"
                    key="Action"
                    align="center"
                    width={getActionWidth()}
                    fixed="right"
                    render={(text, record, index) => (
                        <Space size="small">
                            {permissions?.roles?.editRole ? (
                                <Tooltip title="Edit">
                                    <EditTwoTone
                                        onClick={() => update(record)}
                                    />
                                </Tooltip>
                            ) : (
                                <></>
                            )}

                            {permissions?.roles?.deleteRole ? (
                                <Popconfirm
                                    title="Are you sure to delete this role?"
                                    onConfirm={() =>
                                        confirmDelete(text, record, index)
                                    }
                                    okText="Yes"
                                    cancelText="No"
                                    icon={
                                        <ExclamationCircleTwoTone twoToneColor="red" />
                                    }
                                >
                                    <Tooltip title="Delete">
                                        <DeleteTwoTone twoToneColor="#eb2f96" />
                                    </Tooltip>
                                </Popconfirm>
                            ) : (
                                <></>
                            )}
                        </Space>
                    )}
                />
            ) : (
                <></>
            )}
        </Table>
    );
};

export default RolesTable;

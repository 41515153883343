import deleteUserTypes from "./types";

const initialState = {
    isLoading: false,
    error: null,
};

function deleteUserReducer(state = initialState, action) {
    switch (action.type) {
        case deleteUserTypes.DELETE_USER_REQUEST:
            return { ...state, isLoading: true };
        case deleteUserTypes.DELETE_USER_SUCCESS:
            return { ...state, isLoading: false };
        case deleteUserTypes.DELETE_USER_FAILURE:
            return { ...state, isLoading: false, error: action.error };
        default:
            return state;
    }
}

export default deleteUserReducer;

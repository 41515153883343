import React, { useState, cloneElement } from "react";
import ReactJson from 'react-json-view';
import {
    Modal,
    Tooltip,
} from "antd";
import {
    EyeOutlined,
} from "@ant-design/icons";

const DisplayJsonModalModal = ({ title, icon, style, json }) => {
    const [ visible, setVisible ] = useState(false);

    const cloneElementAndModify = ({ component, props }) => {
        if (!component) {
            return <></>;
        }
        const element = cloneElement(
            component,
            props,
        );
        return element;
    };

    const openModal = () => {
        setVisible(true);
    };

    const closeModal = () => {
        setVisible(false);
    };
    
    return (
        <>
            <Tooltip
                title='View'
            >
                {cloneElementAndModify({
                    component: icon ?? <EyeOutlined />,
                    props: {
                        onClick: openModal,
                        style: {
                            color: "#1890ff",
                            ...style,
                        },
                    },
                })}
            </Tooltip>
            <Modal
                title={title ?? "Display JSON Modal"}
                visible={visible}
                onOk={closeModal}
                onCancel={closeModal}
                footer={null}
                destroyOnClose
            >
                <ReactJson
                    src={json}
                    name={false}
                    displayDataTypes={false}
                />
            </Modal>
        </>
    );
};

export default DisplayJsonModalModal;

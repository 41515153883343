import requests from "../../services/requests";
import types from "./types";
import { message } from "antd";
import { updateTaskStatus } from "../updateTaskStatus/action";

export const removeFpmsPlayer = (payload, taskObjId, token) => {
    return async (dispatch) => {
        if (!(payload.platformId && payload.playerId && payload.phoneNumber)) {
            message.error(`Platform ID, Player ID, and Phone Number is required.`);
            return;
        }
        try {
            dispatch(REMOVE_FPMS_PLAYER_REQUEST());

            const response = await requests.post(token, "/result/removeFpmsPlayer", payload);
            if (!response?.data?.success) {
                const error = { response: response };
                throw error;
            }
            dispatch(REMOVE_FPMS_PLAYER_SUCCESS());
            message.success("Successfully removed fpms player", 1);

            dispatch(updateTaskStatus(
                { _id: taskObjId },
                1,
                { updateIfRunning: true },
                1,
                10,
                token,
                {}
            ));
        } catch (e) {
            dispatch(REMOVE_FPMS_PLAYER_FAILURE(e.response.data.message));
            const errorMessage = e.response.data.message ?? e.response.data.error ?? "Error!";
            message.error(errorMessage, 1);
        }
    };
};

export const REMOVE_FPMS_PLAYER_REQUEST = () => {
    return {
        type: types.REMOVE_FPMS_PLAYER_REQUEST,
    };
};

export const REMOVE_FPMS_PLAYER_SUCCESS = () => {
    return {
        type: types.REMOVE_FPMS_PLAYER_SUCCESS,
    };
};

export const REMOVE_FPMS_PLAYER_FAILURE = (error) => {
    return {
        type: types.REMOVE_FPMS_PLAYER_FAILURE,
        error: error,
    };
};

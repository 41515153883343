import React, { useEffect } from "react";
import {
    Breadcrumb,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import SearchForm from "../../components/SearchForm/SearchForm";
import ActionLogTable from "../../components/ActionLogTable/ActionLogTable";
import { showActionLog } from "../../store/actionLog/action";
import { UPDATE_SELECTED_FIELDS } from "../../store/searchForm/action";

const ActionLog = () => {
    const dispatch = useDispatch();
    const { token, permissions } = useSelector((state) => state.auth);

    useEffect(() => {
        dispatch(UPDATE_SELECTED_FIELDS({page: []}));
        dispatch(showActionLog(
            {},
            1,
            10,
            token
        ));
    });

    if (permissions && !permissions.actionLog?.getActionLog) {
        return <Redirect to="/" />;
    }

    return (
        <>
            <Breadcrumb style={{ margin: "16px 0" }}>
                <Breadcrumb.Item>Show Action Log</Breadcrumb.Item>
            </Breadcrumb>
            <div
                className="site-layout-background"
                style={{ padding: 24, minHeight: 360 }}
            >
                <SearchForm
                    searchBy = {[
                        {
                            name: "Username",
                            type: "text",
                            dataKey: "username"
                        },
                        {
                            name: "IP Address",
                            type: "text",
                            dataKey: "ipAddress"
                        },
                        {
                            name: "Action Category",
                            type: "text",
                            dataKey: "actionCategory"
                        },
                        {
                            name: "Date Time",
                            type: "dateRange",
                            dataKey: "createdAt"
                        },
                    ]}
                    showRecord = {showActionLog}
                />
                <ActionLogTable />
            </div>
        </>
    );
};

export default ActionLog;

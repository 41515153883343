const authTypes = {
    LOGIN_REQUEST: "LOGIN_REQUEST",
    LOGIN_SUCCESS: "LOGIN_SUCCESS",
    LOGIN_FAILURE: "LOGIN_FAILURE",

    LOGOUT_REQUEST: "LOGOUT_REQUEST",
    LOGOUT_SUCCESS: "LOGOUT_SUCCESS",
    LOGOUT_FAILURE: "LOGOUT_FAILURE",

    VALIDATE_TOKEN: "VALIDATE_TOKEN",

    UPDATE_AUTH_USER_PERMISSIONS: "UPDATE_AUTH_USER_PERMISSIONS",

    CHANGE_COUNTRY: "CHANGE_COUNTRY",
};

export default authTypes;

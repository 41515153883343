import updateTaskTypes from "./types";

const initialState = {
    isLoading: false,
    error: null,
};

function updateTaskReducer(state = initialState, action) {
    switch (action.type) {
        case updateTaskTypes.UPDATE_TASK_REQUEST:
            return { ...state, isLoading: true };
        case updateTaskTypes.UPDATE_TASK_SUCCESS:
            return { ...state, isLoading: false };
        case updateTaskTypes.UPDATE_TASK_FAILURE:
            return { ...state, isLoading: false, error: action.error };
        default:
            return state;
    }
}

export default updateTaskReducer;

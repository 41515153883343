// Need to provide searchBy and showRecord when using.
// showRecord = function to show data
// pageSize = allow to change the default page size after reset and search. If not provided default is 10.
// serchBy = the field that needs to be used for search
// fixedQuery = Fixed query, added to the query every time
// isModal = if used in modal, it must be set to true
// externalComponent = allow adding external components
// Example:
//    <SearchForm
//        searchBy={[ object ]}
//        showRecord={ function }
//        pageSize={5}
//        fixedQuery={{ field: value }}
//        isModal={ boolean }
//        externalComponent={[ component ]}
//    />
// searchBy example:
//     {
//         name: "Status",
//         type: "selectOption",
//         dataKey: "status",
//         selectOption: [
//             {
//                 name: "Verified",
//                 value: 1,
//             },
//             {
//                 name: "Not Verified",
//                 value: 2,
//             },
//         ],
//         allowNull: true,
//         fixed: true,
//         defaultValue = value,
//         multiple = true,
//     },
// name = label
// type = component
//    text = <Input />,
//    dateRange = <RangePicker />,
//    selectOption = select option,
//    number = <Input type="number" /> ~ <Input type="number" />
// dataKey = database collection field
// selectOption = option, type: selectOption only needed
// multiple = used in select option only, can change whether multiple selections are required. If not provided, default is false
// min = used in number only, can set the minumum amount. If not provided, default is 0
// max = used in number only, can set the maximum amount. If not provided, will be no limit
// step = used in number only, can set the number increased or decreased. If not provided, default is 1
// allowNull determines whether it can be null. If allowNull is not provided, default is false
// fixed can decide whether to make it unable to cancel the field. If not provided, default is false
// defaultValue can set default value. The format of dateRange must be MM/DD/YYYY, and the data type of dateRange must be array

import React from "react";
import {
    Card,
    Row,
    Form,
    Button,
    Col,
    Input,
    Select,
    DatePicker,
} from "antd";
import { Option } from "antd/lib/mentions";
import { useDispatch, useSelector } from "react-redux";
import { UPDATE_SELECTED_FIELDS } from "../../store/searchForm/action";
import moment from 'moment';

const SearchForm = ({ singleMode, searchBy, showRecord, pageSize, fixedQuery, isModal, externalComponent }) => {
    const dispatch = useDispatch();
    const { selectedFields } = useSelector((state) => state.searchForm);
    const token = useSelector((state) => state.auth.token);
    const [form] = Form.useForm();
    const { RangePicker } = DatePicker;

    const tailLayout = {
        wrapperCol: { offet: 2, span: 22 },
    };
    const layout = {
        labelCol: { span: 4, style: { "white-space": "normal" } },
        wrapperCol: { span: 20 },
    };

    const datePickerOption = {
    	year: "numeric",
    	month: "2-digit",
    	day: "2-digit",
    };

    const convertDateFormat = (text, option) => {
        const date = new Date(text)
            .toLocaleString("en-GB", option)
            .replace(/(\d+)\/(\d+)\/(\d+)/, "$1/$2/$3")
            .replace(",", "\n");

        return date;
    };

    const formInitialValues = () => {
        let initialValues = {};
        searchBy.forEach(field => {
            if (field.defaultValue && (field.defaultValue.constructor !== Array || field.defaultValue.length)) {
                if (field.type === "selectOption") {
                    initialValues = {
                        ...initialValues,
                        [field.dataKey]: Array.isArray(field.defaultValue)
                            ? String(field.defaultValue)
                            : field.defaultValue
                    };
                } else if (field.type === "dateRange") {
                    const fromDate = convertDateFormat(field.defaultValue[0] ?? null, datePickerOption);
                    const toDate = field.defaultValue[1] ? convertDateFormat(field.defaultValue[1], datePickerOption) : fromDate;
                    initialValues = {
                        ...initialValues,
                        [field.dataKey]: [
                            moment(fromDate, "DD/MM/YYYY").isValid()
                            ? moment(fromDate, "DD/MM/YYYY")
                            : null,

                            moment(toDate, "DD/MM/YYYY").isValid()
                            ? moment(toDate, "DD/MM/YYYY")
                            : null
                        ]
                    };
                }
                // if (field.type === "number") {
                //     if (Array.isArray(field.defaultValue) && field.defaultValue.length === 2) {
                //         initialValues = {
                //             ...initialValues,
                //             [field.dataKey]: field.defaultValue,
                //             [`${field.dataKey}-min`]: Number.isInteger(field.defaultValue[0]) ? field.defaultValue[0] : 0,
                //             [`${field.dataKey}-max`]: Number.isInteger(field.defaultValue[1]) ? field.defaultValue[1] : 0,
                //         };
                //     }
                // } else {
                //     initialValues = {
                //         ...initialValues,
                //         [field.dataKey]: field.defaultValue
                //     };
                // }
            }
        });
        return initialValues;
    };

    const onMinNumberChange = (e, datakey) => {
        let formValues = form.getFieldsValue();
        let value = e.target.value ? Number(e.target.value) : null;
        value = formValues[`${datakey}-max`] !== null && value > formValues[`${datakey}-max`]
            ? formValues[`${datakey}-max`]
            : value;
        form.setFieldsValue({
            [datakey]:
                value !== null || formValues[`${datakey}-max`] !== null
                    ? [value, formValues[`${datakey}-max`]]
                    : null,
            [`${datakey}-min`]: value
        });
    };

    const onMaxNumberChange = (e, datakey) => {
        let formValues = form.getFieldsValue();
        let value = e.target.value ? Number(e.target.value) : null;
        value = value !== null && value < formValues[`${datakey}-min`]
            ? formValues[`${datakey}-min`]
            : value;
        form.setFieldsValue({
            [datakey]:
                value !== null || formValues[`${datakey}-min`] !== null
                    ? [formValues[`${datakey}-min`], value]
                    : null,
            [`${datakey}-max`]: value
        });
    };

    const generateComponent = (field) => {
        return (
            <Form.Item
                {...tailLayout}
                name={field.dataKey}
                label={field.name}
                rules={[
                    {
                        required: field.allowNull ? !field.allowNull : true,
                        message: `Please ${
                            field.type === "selectOption" || field.type === "dateRange"
                            ? "select"
                            : "input"
                        } ${field.name}!`,
                    },
                ]}
            >
                {field.type === "text"
                    ? <Input />
                    : field.type === "dateRange"
                    ? <RangePicker
                        format="DD/MM/YYYY"
                        style={{
                            width: "100%"
                        }}
                    />
                    : field.type === "selectOption"
                    ? <Select
                        showSearch
                        style={{
                            textAlign: "left"
                        }}
                        mode={field.multiple ? "multiple" : null}
                        filterOption={(input, option) => {
                            return option.children.toLowerCase().indexOf(input.toLowerCase()) > -1;
                        }}
                    >
                        {field.selectOption ? (
                            field.selectOption.map(option => (
                                <Option value={option.multipleValues ? String(option.multipleValues) : option.value}>
                                    {option.name}
                                </Option>
                            ))
                        ) : (
                            <></>
                        )}
                    </Select>
                    : field.type === "number"
                    ? <Row>
                        <Col span={11}>
                            <Form.Item
                                name={`${field.dataKey}-min`}
                            >
                                <Input
                                    type="number"
                                    style={{
                                        textAlign: "center",
                                    }}
                                    placeholder="Minimum"
                                    min={field.min ? field.min : 0}
                                    step={field.step ? field.step : undefined}
                                    onBlur={(e) => onMinNumberChange(e, field.dataKey)}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={2}>
                            <Input
                                style={{
                                    textAlign: "center",
                                }}
                                placeholder="~"
                                disabled
                            />
                        </Col>
                        <Col span={11}>
                            <Form.Item
                                name={`${field.dataKey}-max`}
                            >
                                <Input
                                    type="number"
                                    style={{
                                        textAlign: "center",
                                    }}
                                    placeholder="Maximum"
                                    max={field.max ? field.max : undefined}
                                    step={field.step ? field.step : undefined}
                                    onBlur={(e) => onMaxNumberChange(e, field.dataKey)}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    : <></>
                }
            </Form.Item>
        );
    };

    const onReset = () => {
        form.resetFields();
        let query = {};
        let selectedField = [];
        searchBy.forEach(field => {
            if (field.defaultValue) {
                query[field.dataKey] = field.defaultValue;
                !field.fixed ? selectedField.push(field.name) : <></>;
            }
        });
        if (fixedQuery) {
            query = {
                ...fixedQuery,
                ...query
            };
        }
        dispatch(UPDATE_SELECTED_FIELDS(isModal ? { modal: selectedField } : { page: selectedField }));
        dispatch(showRecord(
            query,
            1,
            pageSize ? pageSize : 10,
            token
        ));
    };

    const onSearch = () => {
        let values = form.getFieldsValue();
        Object.keys(values).forEach(key => {
            if (values[key] === undefined) values[key] = null;
        });
        searchBy.forEach(field => {
            field.selectOption && field.selectOption.forEach(option => {
                if (option.multipleValues && values[field.dataKey] === String(option.multipleValues)) {
                    values[field.dataKey] = option.multipleValues;
                }
            });
        });
        if (fixedQuery) {
            values = {
                ...fixedQuery,
                ...values
            };
        }
        dispatch(showRecord(
            values,
            1,
            pageSize ? pageSize : 10,
            token
        ));
    };

    const onChange = (value) => {
        dispatch(UPDATE_SELECTED_FIELDS(isModal ? { modal: value } : { page: value }));
    };

    const selectBefore = (
        <Select
            onChange={onChange}
            className="select-before"
            mode={!singleMode ? "multiple" : null}
            maxTagCount={5}
            showArrow
            value={isModal ? selectedFields.modal : selectedFields.page}
            allowClear
        >
            {searchBy.map((field) => (
                !field.fixed
                    ? <Option value={field.name}>
                        {field.name}
                    </Option>
                    : <></>
            ))}
        </Select>
    );

    return (
        <Card>
            <Form
                name="Search Form"
                {...layout}
                onFinish={onSearch}
                form={form}
                style={{
                    position: "relative",
                    borderSpacing: 0,
                }}
                initialValues={formInitialValues()}
            >
                {searchBy.map(field => {
                    return field.fixed ? generateComponent(field) : <></>;
                })}

                <Form.Item {...tailLayout} label={"Search Field"}>
                    {selectBefore}
                </Form.Item>

                {searchBy.map(field => {
                    return selectedFields[isModal ? "modal" : "page"].includes(field.name) && !field.fixed ? (
                        generateComponent(field)
                    ) : (
                        <></>
                    );
                })}

                <Row justify="end">
                    {externalComponent ? (
                        externalComponent.map(component => {
                            return (
                                <Col style={{ paddingRight: "1rem" }}>
                                    {component}
                                </Col>
                            );
                        })
                    ) : (
                        <></>
                    )}
                    <Col style={{ paddingRight: "1rem" }}>
                        <Button
                            size="small"
                            style={{
                                borderRadius: 5,
                            }}
                            onClick={onReset}
                        >
                            Reset
                        </Button>
                    </Col>
                    <Col>
                        <Button
                            type="primary"
                            htmlType="submit"
                            size="small"
                            style={{
                                borderRadius: 5,
                            }}
                        >
                            Submit
                        </Button>
                    </Col>
                </Row>
            </Form>
        </Card>
    );
};

export default SearchForm;

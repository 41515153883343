import { message } from "antd";
import requests from "../../services/requests";
import { CLOSE_ADD_ROLE_MODAL } from "../addRoleModal/action";
import { logout } from "../auth/action";
import { showRoles } from "../roles/action";
import types from "./types";

export const addRole = (data, page, pageSize, form, token) => {
    return async (dispatch) => {
        try {
            dispatch(ADD_ROLE_REQUEST());
            await requests.post(token, "/role/add", data);
            dispatch(ADD_ROLE_SUCCESS());
            form.resetFields();
            message.success("Successfully added a new Role", 1);
            dispatch(CLOSE_ADD_ROLE_MODAL());
            dispatch(showRoles(page, pageSize, token));
        } catch (e) {
            if (e.response) {
                dispatch(ADD_ROLE_FAILURE(e));
                if (e.response.status === 401) {
                    message.error("Account is Unathorized");
                    dispatch(logout());
                } else {
                    if (
                        e.response &&
                        e.response.data &&
                        e.response.data.response &&
                        e.response.data.response.message
                    ) {
                        message.error(e.response.data.message, 1);
                    } else if (e.response.data && e.response.data.message) {
                        message.error(e.response.data.message, 1);
                    } else if (
                        e.response &&
                        e.response.data &&
                        e.response.data.error
                    ) {
                        message.error(e.response.data.error, 1);
                    } else {
                        message.error("Error!", 1);
                    }
                }
            }
        }
    };
};

export const ADD_ROLE_REQUEST = () => {
    return {
        type: types.ADD_ROLE_REQUEST,
    };
};

export const ADD_ROLE_SUCCESS = () => {
    return {
        type: types.ADD_ROLE_SUCCESS,
    };
};

export const ADD_ROLE_FAILURE = (error) => {
    return {
        type: types.ADD_ROLE_FAILURE,
        error: error,
    };
};

export const SET_ADD_PERMISSIONS = (permissions) => {
    return {
        type: types.SET_ADD_PERMISSIONS,
        permissions: permissions,
    };
};

import types from "./types";

export const OPEN_ADD_ROLE_MODAL = () => {
    return {
        type: types.OPEN_ADD_ROLE_MODAL,
    };
};

export const CLOSE_ADD_ROLE_MODAL = () => {
    return {
        type: types.CLOSE_ADD_ROLE_MODAL,
    };
};

import actionLogTypes from "./types";

const initialState = {
    isLoading: false,
    actionLog: [],
    query: {},
    count: 1,
    totalCount: 1,
    page: 1,
    pageSize: 10,
};

function actionLogReducer(state = initialState, action) {
    switch (action.type) {
        case actionLogTypes.SHOW_ACTION_LOG_REQUEST:
            return { ...state, isLoading: true };
        case actionLogTypes.SHOW_ACTION_LOG_SUCCESS:
            return {
                ...state,
                isLoading: false,
                actionLog: action.actionLog,
                query: action.query,
                count: action.count,
                totalCount: action.totalCount,
                page: action.page,
                pageSize: action.pageSize,
            };
        case actionLogTypes.SHOW_ACTION_LOG_FAILURE:
            return { ...state, isLoading: false, error: action.error };
        default:
            return state;
    }
}

export default actionLogReducer;

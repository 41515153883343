import removeFpmsPlayerTypes from "./types";

const initialState = {
    isLoading: false,
    error: null,
};

function removeFpmsPlayerReducer(state = initialState, action) {
    switch (action.type) {
        case removeFpmsPlayerTypes.REMOVE_FPMS_PLAYER_REQUEST:
            return { ...state, isLoading: true };
        case removeFpmsPlayerTypes.REMOVE_FPMS_PLAYER_SUCCESS:
            return { ...state, isLoading: false };
        case removeFpmsPlayerTypes.REMOVE_FPMS_PLAYER_FAILURE:
            return { ...state, isLoading: false, error: action.error };
        default:
            return state;
    }
}

export default removeFpmsPlayerReducer;

import searchFormTypes from "./types";

const initialState = {
    selectedFields: {
        page: [],
        modal: []
    },
};

function searchFormReducer(state = initialState, action) {
    switch (action.type) {
        case searchFormTypes.UPDATE_SELECTED_FIELDS:
            let selectedFields = {...state.selectedFields, ...action.selectedFields};
            return { ...state, selectedFields: selectedFields };
        default:
            return state;
    }
}

export default searchFormReducer;

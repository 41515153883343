import React, { useEffect, useState, useRef, cloneElement, useCallback } from "react";
import {
    Modal,
    Tooltip,
    Carousel,
    Image,
} from "antd";
import {
    EyeOutlined,
    LeftOutlined,
    RightOutlined,
} from "@ant-design/icons";
import config from "../../config";
import reactImageSize from 'react-image-size';
import "./DisplayVisualMediaModal.css";
let eventListener = (event) => {
    carouselEvent(event.code);
};
let carouselEvent = null;

const DisplayVisualMediaModal = ({ title, icon, style, images, videos }) => {
    const [ visible, setVisible ] = useState(false);
    const [ currentIndex, setCurrentIndex ] = useState(0);
    const [ modalDimensions, setModalDimentions ] = useState({ width: '60rem', height: '60rem' });
    const carousel = useRef();

    carouselEvent = code => {
        if (!carousel || !carousel.current) return;
        switch(code) {
            case 'ArrowLeft':
                carousel.current.prev();
                break;
            case 'ArrowRight':
                carousel.current.next();
                break;
            default:
                break;
        }
    };

    const initialModalDimensions = useCallback(async () => {
        const image = Array.isArray(images) && images.length ? images[0] : images;
        if (!image) return;
        const { width, height } = await reactImageSize(`${config.api_uri}${image}`);
        setModalDimentions({
            width,
            height,
        });
        setCurrentIndex(0);
    }, [images]);

    const cloneElementAndModify = ({ component, props }) => {
        if (!component) {
            return <></>;
        }
        const element = cloneElement(
            component,
            props,
        );
        return element;
    };

    useEffect(() => {
        if (images && images.length) initialModalDimensions();
    }, [visible, images, initialModalDimensions]);

    const openModal = () => {
        window.addEventListener('keydown', eventListener);
        setVisible(true);
    };

    const closeModal = () => {
        window.removeEventListener('keydown', eventListener);
        setVisible(false);
    };

    const handleBeforeChange = async (prevIndex, nextIndex) => {
        if (Array.isArray(images)) {
            const { width, height } = await reactImageSize(`${config.api_uri}${images[nextIndex]}`);
            setModalDimentions({
                width,
                height,
            });
            setCurrentIndex(nextIndex);
        }
    };

    return (
        <>
            <Tooltip
                title='View'
            >
                {cloneElementAndModify({
                    component: icon ?? <EyeOutlined />,
                    props: {
                        onClick: openModal,
                        style: {
                            color: "#1890ff",
                            ...style,
                        },
                    },
                })}
            </Tooltip>
            <Modal
                title={title || `${images ? 'Images' : videos ? 'Video' : ''}`}
                visible={visible}
                onOk={closeModal}
                onCancel={closeModal}
                keyboard
                width={modalDimensions.width}
                height={modalDimensions.height}
                footer={null}
                destroyOnClose
            >
                <div
                    style={{
                        textAlign: 'center',
                        color: 'grey',
                    }}
                >
                    {
                        currentIndex+1
                    } / {
                        images
                            ? images.constructor === Array
                                ? images.length
                                : 1
                            : videos.constructor === Array
                                ? videos.length
                                : 1
                    }
                </div>
                <Carousel
                    ref={carousel}
                    arrows
                    dots={false}
                    style={{
                        textAlign: 'center'
                    }}
                    prevArrow={<LeftOutlined />}
                    nextArrow={<RightOutlined />}
                    beforeChange={handleBeforeChange}
                >
                    {
                        images && Array.isArray(images) && images.length
                        ? images.map((image, index) => {
                                return (
                                    image && currentIndex === index
                                    ? <Image
                                        src={`${config.api_uri}${image}`}
                                        alt={image.slice(image.lastIndexOf('/') + 1)}
                                        preview={false}
                                    />
                                    : <></>
                                );
                            })
                        : images
                        ? <Image
                            src={`${config.api_uri}${images}`}
                            alt={images.slice(images.lastIndexOf('/') + 1)}
                            preview={false}
                        />
                        : videos
                        ? <video
                            controls
                            autoPlay
                            src={`${config.api_uri}${videos}`}
                        />
                        : <></>
                    }
                </Carousel>
            </Modal>
        </>
    );
};

export default DisplayVisualMediaModal;

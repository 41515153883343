import { combineReducers } from "redux";

import authReducer from "./auth/reducer";
import changePasswordReducer from "./changePassword/reducer";
import searchFormReducer from "./searchForm/reducer";

import userReducer from "./users/reducer";
import addUserReducer from "./addUser/reducer";
import resetPasswordReducer from "./resetPassword/reducer";
import deleteUserReducer from "./deleteUser/reducer";

import roleReducer from "./roles/reducer";
import addRoleReducer from "./addRole/reducer";
import addRoleModalReducer from "./addRoleModal/reducer";
import updateRoleModalReducer from "./updateRoleModal/reducer";
import updateRoleReducer from "./updateRole/reducer";
import deleteRoleReducer from "./deleteRole/reducer";

import settingsReducer from "./settings/reducer";
import updateSettingsReducer from "./updateSettings/reducer";

import actionLogReducer from "./actionLog/reducer";

import taskReducer from "./task/reducer";
import addTaskReducer from "./addTask/reducer";
import updateTaskReducer from "./updateTask/reducer";
import updateTaskStatusReducer from "./updateTaskStatus/reducer";
import deleteTaskReducer from "./deleteTask/reducer";

import resultReducer from "./result/reducer";
import resultLogReducer from "./resultLog/reducer";
import removeFpmsPlayerReducer from "./removeFpmsPlayer/reducer";

const appReducer = combineReducers({
    auth: authReducer,
    changePassword: changePasswordReducer,
    searchForm: searchFormReducer,

	users: userReducer,
    addUser: addUserReducer,
    resetPassword: resetPasswordReducer,
    deleteUser: deleteUserReducer,

    roles: roleReducer,
    addRole: addRoleReducer,
    addRoleModal: addRoleModalReducer,
    updateRoleModal: updateRoleModalReducer,
    updateRole: updateRoleReducer,
    deleteRole: deleteRoleReducer,

    settings: settingsReducer,
    updateSettings: updateSettingsReducer,

    actionLog: actionLogReducer,

    task: taskReducer,
    addTask: addTaskReducer,
    updateTask: updateTaskReducer,
    updateTaskStatus: updateTaskStatusReducer,
    deleteTask: deleteTaskReducer,

    result: resultReducer,
    resultLog: resultLogReducer,
    removeFpmsPlayer: removeFpmsPlayerReducer,
});

const rootReducer = (state, action) => {
    if(action.type === "LOGOUT_SUCCESS"){
        state = undefined;
    }
    return appReducer(state, action);
};

export default rootReducer;

import requests from "../../services/requests";
import { logout } from "../auth/action";
import types from "./types";

export const showResultLog = (query, page, pageSize, token) => {
    return async (dispatch) => {
        let response;
        const options = { query: query, page: page, pageSize: pageSize };
        try {
            dispatch(SHOW_RESULT_LOG_REQUEST());
            response = await requests.get(token, "/result/getResultLog", options);
            dispatch(SHOW_RESULT_LOG_SUCCESS(response.data));
        } catch (e) {
            if (e.response) {
                dispatch(SHOW_RESULT_LOG_FAILURE(e));
                if (e.response.status === 401) {
                    dispatch(logout());
                };
            };
        };
    };
};

export const SHOW_RESULT_LOG_REQUEST = () => {
    return {
        type: types.SHOW_RESULT_LOG_REQUEST,
    };
};

export const SHOW_RESULT_LOG_SUCCESS = (resultLog) => {
    return {
        type: types.SHOW_RESULT_LOG_SUCCESS,
        resultLog: resultLog.data,
        query: resultLog.query,
        count: resultLog.count,
        totalCount: resultLog.totalCount,
        page: resultLog.page,
        pageSize: resultLog.pageSize,
    };
};

export const SHOW_RESULT_LOG_FAILURE = (error) => {
    return {
        type: types.SHOW_RESULT_LOG_FAILURE,
        error: error,
    };
};

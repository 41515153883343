import { message } from "antd";
import requests from "../../services/requests";
import { showTask } from "../task/action";
import types from "./types";

export const updateTaskStatus = (record, status, options, page, pageSize, token, query) => {
    return async (dispatch) => {
        try {
            dispatch(UPDATE_TASK_STATUS_REQUEST());
            await requests.put(token, "/task/updateStatus", {
                id: record._id,
                status,
                options,
            });
            dispatch(UPDATE_TASK_STATUS_SUCCESS());
            message.success("Successfully updated task status", 1);
        } catch (e) {
            dispatch(UPDATE_TASK_STATUS_FAILURE(e.response.data.message));
            message.error(e.response.data.message, 1);
        }
        try {
            dispatch(showTask(
                query,
                page,
                pageSize,
                token,
            ));
        } catch (e) {
            message.error(e?.response?.data?.message || e?.message, 1);
        }
    };
};

export const UPDATE_TASK_STATUS_REQUEST = () => {
    return {
        type: types.UPDATE_TASK_STATUS_REQUEST,
    };
};

export const UPDATE_TASK_STATUS_SUCCESS = () => {
    return {
        type: types.UPDATE_TASK_STATUS_SUCCESS,
    };
};

export const UPDATE_TASK_STATUS_FAILURE = (error) => {
    return {
        type: types.UPDATE_TASK_STATUS_FAILURE,
        error: error,
    };
};
